import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';

const apiHost = process.env.REACT_APP_API_HOST;

export const homeService = createApi({
  reducerPath: 'homeService',
  tagTypes: ['Projects'],
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };
        return headers;
      }
      return {};
    },
  }),
  endpoints: (builder) => ({
    getProjectsForOwner: builder.mutation({
      query: ({ size, owner }) => ({
        url: `/projects/filter?size=${size}`,
        method: 'POST',
        data: { owner },
      }),
      providesTags: ['Projects'],
    }),
  }),
});

export const { useGetProjectsForOwnerMutation } = homeService;
