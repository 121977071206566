import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';

const apiHost = process.env.REACT_APP_API_HOST;

export const notificationService = createApi({
  reducerPath: 'notificationService',
  tagTypes: ['Notifications'],
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };
        return headers;
      }
      return {};
    },
  }),
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: ({ all }) => ({
        url: `/users/me/notifications${all ? '?all=true' : ''}`,
        method: 'GET',
      }),
      providesTags: ['Notifications', 'UpdateNotifications'],
    }),
    deleteNotifications: builder.mutation({
      query: ({ id }) => ({
        url: '/users/me/notifications',
        method: 'DELETE',
        data: { id },
      }),
      invalidatesTags: ['UpdateNotifications'],
    }),
  }),
});

export const { useGetNotificationsQuery, useDeleteNotificationsMutation } = notificationService;
