import { useForm } from 'react-hook-form';
import { useGetAllCommentsQuery, useAddCommentMutation } from '../../../Redux/services/task';

function TaskComments({ taskId }) {
  const { data: comments } = useGetAllCommentsQuery({ id: taskId });
  const [addComment, { isLoading }] = useAddCommentMutation();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      comment: '',
    },
  });
  const onSubmit = async (data) => {
    const reqBody = {
      message: data.comment.replace(/^\n+|\n+$/g, ''),
    };
    const response = await addComment({ id: taskId, data: reqBody });
    reset({
      comment: '',
    });
    if (response?.data?.success) {
      window.toastr.success(response.data.message);
    } else {
      window.toastr.error("Couldn't add Comment ");
    }
  };

  const handleKeyDown = (e) => {
    // Allow Shift+Enter for new lines
    if (e.code === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="col-12 mt-0">
      <div className="card card-flush pb-3">
        <div className="card-header py-4 pb-1 minimize">
          <p className="card-title align-items-start flex-column m-0">
            <span className="w-bold h4">Comments</span>
            <span className="text-muted mt-1 fw-semibold fs-6">Internal comments
              within the team
            </span>
          </p>
          <div className="card-toolbar mt-0 mb-0">
            <button
              type="button"
              aria-label="hidden"
              className="btn btn-icon btn-sm btn-light-primary justify-content-center"
            >
              <i className="fas fa-plus" />
            </button>
          </div>
        </div>
        <div className="card-body py-2 collapse">
          <div className="mt-0">
            <div style={{ maxHeight: '500px', overflow: 'auto' }}>
              {
              comments?.data?.map((comment) => (
                <div key={comment.id} className="d-flex flex-row p-5 mt-1 border border-dashed border-gray-300 rounded">
                  <div
                    className="symbol symbol-35px symbol-circle"
                    data-bs-toggle="tooltip"
                    data-bs-original-title="Mohit Parashar"
                    data-kt-initialized="1"
                  >
                    <span
                      className="symbol-label bg-danger solid text-inverse-danger fw-normal"
                    >{comment?.initials}
                    </span>
                  </div>

                  <div className="flex-fill ms-5">
                    <div className="d-flex flex-wrap justify-content-between">
                      <span className="mb-0 author">
                        <span
                          className="fw-bold text-gray-900"
                        >{comment?.fullName}
                        </span>
                        <br />
                        <small className="text-muted msg-time">
                          {comment?.timestamp}
                        </small>
                      </span>

                    </div>
                    <span className="text-gray-800 " style={{ overflowWrap: 'anywhere' }}>
                      {comment?.message}
                    </span>

                  </div>
                </div>
              ))
            }
            </div>
            <textarea
              {...register('comment', {
                required: { value: true, message: 'Required' },
                validate: (value) => {
                  if (value.trim() === '') {
                    return 'Required';
                  }
                  if (value.trim().length < 1) {
                    return "Input can't be less than 1 characters";
                  }
                  return true;
                },
              })}
              onKeyDown={handleKeyDown}
              disabled={isLoading}
              className="form-control mt-5"
              placeholder="Add a comment"
            />
            {errors?.comment && (
              <div className="text-danger fs-sm">{ errors?.comment?.message }</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskComments;
