import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import AddCompanyModal from './AddCompanyModal';
import SettingsModal from './SettingsModal';
import ClientHistoryModal from './ClientHistoryModal';
import { useDeleteClientMutation, useGetClientsListQuery } from '../../Redux/services/company';
import Pagination from '../custom/Pagination';
import { decryptPassword } from '../../utils/cryptoUtils';

const clientStatus = {
  1: 'Active',
  2: 'Inactive',
};

const customSelectStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '500',
    opacity: '.7',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '1.15rem',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    borderRadius: '1.15rem',
    backgroundColor: 'var(--bs-dropdown-bg)',
    fontSize: '13px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'var(--bs-dropdown-bg)',
    color: state.isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
    ':hover': {
      backgroundColor: 'var(--bs-component-hover-bg)',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--bs-gray-300)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
    fontSize: '1.1rem',
  }),
};

function Companies() {
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [sortColumn, setSortColumn] = useState('clientName');
  const [sortOrder, setOrderSort] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [clientData, setClientData] = useState({});
  const [modalType, setModalType] = useState('add');
  const [clientId, setClientId] = useState('');
  const [clientIdForSettings, setClientIdForSettings] = useState('');
  const [clientIdForHistory, setClientIdForHistory] = useState('');
  const [filters, setFilters] = useState({});
  const [deleteClient] = useDeleteClientMutation();
  const size = 5;
  const { data: companiesData } = useGetClientsListQuery({
    filters, sortColumn, sortOrder, page: currentPage, size,
  });
  const { data: clientsData } = useGetClientsListQuery({
    size: 100,
  });

  const {
    control,
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const filterBody = {
      clientName: data.clientName?.value,
      adminName: data.adminName?.value,
      adminEmail: data.adminEmail?.value,
      adminPhoneNo: data.adminPhoneNo?.value,
      status: data.status?.value,
    };
    setFilters(filterBody);
  };

  const renderCompanyNames = () => clientsData?.data?.clients?.map((client) => ({
    value: client?.name,
    label: client?.name,
  }));

  const renderAdminNames = () => clientsData?.data?.clients?.map((client) => ({
    value: client?.adminName,
    label: client?.adminName,
  }));

  const renderStatus = () => ([
    { value: 1, label: 'Active' },
    { value: 2, label: 'Inactive' },
  ]);

  const renderAdminEmails = () => clientsData?.data?.clients?.map((client) => ({
    value: client?.adminEmail,
    label: client?.adminEmail,
  }));

  const renderAdminContacts = () => clientsData?.data?.clients
    ?.filter((client) => (client?.adminPhoneNo))
    .map((client) => ({
      value: client?.adminPhoneNo,
      label: client?.adminPhoneNo,
    }));

  const closeAddCompanyModal = () => {
    setShowAddCompanyModal(false);
    setClientData({});
    setModalType('add');
  };
  const closeSettingsModal = () => {
    setShowSettingsModal(false);
  };
  const closeHistoryModal = () => {
    setShowHistoryModal(false);
    setClientIdForHistory('');
  };

  const handleColumnSort = (columnName) => {
    let orderBy = 'asc';
    if (sortColumn === columnName) {
      orderBy = sortOrder === 'asc' ? 'desc' : 'asc';
    }
    setOrderSort(orderBy);
    setSortColumn(columnName);
    setCurrentPage(1);
  };

  const handleDeleteClient = async (id) => {
    Swal.fire({
      text: 'Are you sure you want to delete this?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'No, Cancel',
      heightAuto: false,
      customClass: {
        confirmButton: 'btn btn-sm btn-danger me-3 delete-btn',
        cancelButton: 'btn btn-sm btn-secondary',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setClientId(id);
          const response = await deleteClient({ id });
          if (response?.data?.success) {
            window.toastr.success(response?.data?.message);
          } else {
            window.toastr.error(response?.error?.data?.message ?? 'Something went wrong.');
          }
        } catch (error) {
          window.toastr.error(error?.data?.message ?? 'Something went wrong');
        }
        setClientId('');
      }
    });
  };

  const handleClear = () => {
    reset({
      clientName: '',
      adminName: '',
      adminEmail: '',
      adminPhoneNo: '',
      status: '',
    });
    setFilters({});
    setCurrentPage(1);
  };

  const handleShowSettings = ({ id }) => {
    setShowSettingsModal(true);
    setClientIdForSettings(id);
  };

  const handleShowHistory = ({ id }) => {
    setShowHistoryModal(true);
    setClientIdForHistory(id);
  };

  useEffect(() => {
    if (companiesData?.data) {
      setTotalCount(companiesData?.data?.totalRecords);
    }
    // eslint-disable-next-line
  }, [companiesData?.data?.totalRecords]);

  return (
    <div className="row pt-0 mt-5">
      <div className="col-md-12">
        <div className="d-flex justify-content-between">
          <div>
            <h2 className="pb-0 mb-0 ms-0 pt-0">Companies</h2>
            <ul className="breadcrumb fw-semibold fs-base mb-1">
              <li className="breadcrumb-item text-gray-900">
                Companies
              </li>
            </ul>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                setShowAddCompanyModal(true);
              }}
            ><i
              className="fa fa-plus fa-plus me-1"
            />Add
            </button>
          </div>
        </div>

        <div className="card priority-card mt-4">

          <div className="card-header d-block pt-3">
            <h5 className="card-title mb-3">Search Filter</h5>
            <div className="row pb-2 g-4">
              <div className="col-md-2">
                <Controller
                  name="clientName"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value}
                      options={renderCompanyNames()}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      isClearable
                      onChange={(val) => {
                        onChange(val);
                      }}
                      styles={customSelectStyles}
                      placeholder="Company Name"
                    />
                  )}
                />
              </div>

              <div className="col-md-2">
                <Controller
                  name="adminName"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value}
                      options={renderAdminNames()}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      isClearable
                      onChange={(val) => {
                        onChange(val);
                      }}
                      styles={customSelectStyles}
                      placeholder="Admin Name"
                    />
                  )}
                />
              </div>
              <div className="col-md-2">
                <Controller
                  name="adminEmail"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value}
                      options={renderAdminEmails()}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      isClearable
                      onChange={(val) => {
                        onChange(val);
                      }}
                      styles={customSelectStyles}
                      placeholder="Admin Email"
                    />
                  )}
                />
              </div>
              <div className="col-md-2">
                <Controller
                  name="adminPhoneNo"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      options={renderAdminContacts()}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      isClearable
                      onChange={(val) => {
                        onChange(val);
                      }}
                      styles={customSelectStyles}
                      placeholder="Admin Contact"
                    />
                  )}
                />
              </div>
              <div className="col-md-2">
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      options={renderStatus()}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      isClearable
                      onChange={(val) => {
                        onChange(val);
                      }}
                      styles={customSelectStyles}
                      placeholder="Status"
                    />
                  )}
                />
              </div>
              <div className="col-md-2">
                <button
                  type="button"
                  className="btn ntn-sm btn-secondary me-sm-3 me-1"
                  onClick={handleSubmit(onSubmit)}
                >Filter
                </button>
                <button
                  onClick={handleClear}
                  type="button"
                  className="btn btn-label-secondary"
                >Clear
                </button>
              </div>
            </div>
          </div>
          <div className="card-body p-4 py-2 table-responsive">

            <table
              className="table data-table align-middle table-row-dashed fs-6 gy-5"
              id="kt_ecommerce_sales_table"
            >
              <thead>
                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  <th className="sortable" style={{ width: '200px' }} onClick={() => handleColumnSort('clientName')}>
                    <span role="button">Company Name</span>
                    {sortColumn === 'clientName' && (
                    <span className="sorting-icons m-2">
                      <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                      <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                    </span>
                    )}
                  </th>
                  <th className="sortable" style={{ width: '120px' }} onClick={() => handleColumnSort('adminName')}>
                    <span role="button">Admin Name</span>
                    {sortColumn === 'adminName' && (
                    <span className="sorting-icons m-2">
                      <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                      <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                    </span>
                    )}
                  </th>
                  <th className="sortable" style={{ width: '120px' }} onClick={() => handleColumnSort('adminEmail')}>
                    <span role="button">Admin Email</span>
                    {sortColumn === 'adminEmail' && (
                    <span className="sorting-icons m-2">
                      <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                      <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                    </span>
                    )}
                  </th>
                  <th className="sortable" style={{ width: '100px' }} onClick={() => handleColumnSort('adminPhoneNo')}>
                    <span role="button">Admin Contact</span>
                    {sortColumn === 'adminPhoneNo' && (
                    <span className="sorting-icons m-2">
                      <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                      <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                    </span>
                    )}
                  </th>
                  <th className="sortable" style={{ width: '100px' }} onClick={() => handleColumnSort('status')}>
                    <span role="button">Status</span>
                    {sortColumn === 'status' && (
                    <span className="sorting-icons m-2">
                      <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                      <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                    </span>
                    )}
                  </th>
                  <th className="sortable" style={{ width: '100px' }}>Actions</th>

                </tr>
              </thead>
              <tbody className="fw-semibold text-gray-600">
                {companiesData?.data?.clients?.map((client) => (
                  <tr key={client?.adminEmail}>
                    <td className="d-flex align-items-center">
                      <div
                        className="symbol symbol-circle symbol-50px overflow-hidden me-3"
                      >
                        <a>
                          <div
                            className="symbol-label solid fs-3 bg-light-success text-success"
                          >
                            {client.adminInitials}
                          </div>
                        </a>
                      </div>
                      <div className="d-flex flex-column">
                        <Link
                          className="text-gray-800 text-hover-primary mb-1"
                        >{client.name}
                        </Link>
                        <span>{client.location ? client.location : '---'}</span>
                      </div>
                    </td>
                    <td>{client.adminName}</td>
                    <td>{client.adminEmail}</td>
                    <td>{client.adminPhoneNo ? client.adminPhoneNo : '---'}</td>
                    <td>
                      <div className={`badge ${clientStatus[client.ClientStatusId] === 'Active' ? 'badge-light-warning' : 'badge-light-dark'} fw-bold`}>{clientStatus[client.ClientStatusId] ?? '---'}
                      </div>
                    </td>
                    <td aria-label="table">
                      <Link
                        onClick={() => {
                          setClientData({
                            ...client, adminPassword: client.adminPassword ? decryptPassword(client.adminPassword) : '',
                          });
                          setModalType('edit');
                          setShowAddCompanyModal(true);
                        }}
                        title="Edit"
                      ><i className="fa fa-edit me-3" />
                      </Link>
                      {clientId === client.id ? (<span className="spinner-border spinner-border-sm align-middle" />)
                        : (<Link type="button" onClick={() => handleDeleteClient(client.id)} title="Delete"><i className="fa fa-trash-can me-3" /></Link>)}
                      <Link type="button" title="Settings" onClick={() => handleShowSettings({ id: client.id })} aria-label="settings"><i className="fa fa-gear me-3" /></Link>
                      <Link type="button" title="History" aria-label="history" onClick={() => handleShowHistory({ id: client.id })}><i className="fa fa-history me-3" /></Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {companiesData?.data?.totalRecords > 0
              ? (
                <Pagination
                  dataPerPage={size}
                  totalData={totalCount}
                  currentPage={currentPage}
                  paginate={setCurrentPage}
                />
              )
              : <div className="text-center fw-bold mx-auto m-4">No Records Found!</div>}
          </div>

        </div>
      </div>
      {showAddCompanyModal
        && (
        <AddCompanyModal
          onHide={closeAddCompanyModal}
          clientData={clientData}
          type={modalType}
        />
        )}
      {showSettingsModal && <SettingsModal onHide={closeSettingsModal} id={clientIdForSettings} />}
      {showHistoryModal
        && <ClientHistoryModal onHide={closeHistoryModal} id={clientIdForHistory} />}
    </div>
  );
}

export default Companies;
