import { useState } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useGetLogHoursQuery, useDeleteLogHoursMutation } from '../../../Redux/services/task';
import { useGetLoggedUserQuery } from '../../../Redux/services/user';
import LogHoursModal from './LogHoursModal';

function TaskLogHours({ taskData }) {
  const { id: taskId, uniqueTaskId } = taskData;
  const { data: loggedHoursEntries } = useGetLogHoursQuery({ id: taskId });
  const [deleteLogHours] = useDeleteLogHoursMutation();
  const { data: loggedInUser } = useGetLoggedUserQuery();
  const [showModalBox, setShowModalBox] = useState(false);
  const [modalValues, setModalValues] = useState({});

  const handleShowDeleteLog = (logId) => {
    Swal.fire({
      text: 'Are you sure you want to delete this log?',
      icon: 'warning',
      iconColor: 'var(--bs-primary)',
      showCancelButton: true,
      heightAuto: false,
      confirmButtonText: 'Yes',
      cancelButtonColor: '#161221',
      customClass: {
        confirmButton: 'btn btn-sm btn-secondary bg-dark',
        cancelButton: 'btn btn-sm btn-outline-secondary ms-3',
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteLogHours({ taskId, logId });
        if (response?.data?.success) {
          window.toastr.success(response.data.message);
        }
      }
    });
  };

  const showTable = () => loggedHoursEntries?.data?.map((entry) => (
    <tr key={entry?.id}>
      <td className="ps-2">
        <strong className="text-gray-900">{entry?.userName ?? ''}</strong>
      </td>
      <td>{moment(entry?.date).format('DD-MM-YYYY') ?? ''}</td>
      <td>{entry?.hours ?? ''}</td>
      <td>
        <button
          className="btn btn-sm btn-icon btn-light-primary rounded-circle"
          type="button"
          aria-label="hidden"
          onClick={(e) => {
            e.preventDefault();
            setModalValues({
              id: entry?.id,
              assigneeId: entry?.userId,
              assigneeName: entry?.userName,
              date: moment(entry?.date),
              hours: entry?.hours,
              description: entry?.description,
              taskId: entry?.taskId,
              taskNo: entry?.uniqueTaskId,
              modalType: parseInt(entry?.userId, 10) === parseInt(loggedInUser?.data?.userId, 10) ? 'update' : 'view',
            });
            setShowModalBox(true);
          }}
        >
          <i className={entry?.userId === loggedInUser?.data?.userId ? 'fas fa-pen' : 'far fa-eye'} />
        </button>
        { entry?.userId === loggedInUser?.data?.userId ? (
          <button
            type="button"
            aria-label="hidden"
            className="btn btn-sm btn-icon btn-light-primary delete-row mobile-delete-row ms-1 rounded-circle"
            onClick={() => handleShowDeleteLog(entry?.id)}
          >
            <i className="fa fa-trash" title="Delete Log Hours" />
          </button>
        ) : null}
      </td>
    </tr>
  ));

  return (
    <>
      <div className="col-12 mt-6">
        <div className="card card-flush h-lg-100">
          <div className="card-header py-4 minimize">
            <p className="card-title align-items-start flex-column">
              <span className="fw-bold h4">Log Hours</span>
              <span className="text-muted mt-1 fw-semibold fs-6">Hours logged for this task</span>
            </p>
            <div className="card-toolbar mt-0">
              <button type="button" aria-label="hidden" className="btn btn-icon btn-sm btn-light-primary justify-content-center minimize">
                <i className="fas fa-plus" />
              </button>
            </div>
          </div>
          <div className="card-body py-2 collapse">
            <div className="table-responsive" style={{ maxHeight: '250px', overflowY: 'auto' }}>
              <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_todo_list">
                <thead>
                  <tr className="text-start text-dark fw-bold fs-7 text-uppercase gs-0">
                    <th className="min-w-80px ps-2">Assignee</th>
                    <th className="min-w-80px">Date</th>
                    <th className="min-w-80px">Log Hours</th>
                    <th className="min-w-80px">Action</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-semibold">
                  {loggedHoursEntries?.length !== 0 && showTable(taskId)}
                </tbody>
              </table>{' '}
            </div>
            <div className="row p-2 ">
              <div className="form-group mt-2">
                <button
                  type="button"
                  data-repeater-create=""
                  className="btn btn-sm btn-primary fw-bold"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalValues({
                      id: '',
                      assigneeId: loggedInUser?.data?.userId,
                      assigneeName: loggedInUser?.data?.fullName,
                      date: moment().format('YYYY-MM-DD'),
                      hours: '01:00',
                      description: '',
                      taskId,
                      taskNo: uniqueTaskId,
                      modalType: 'add',
                    });
                    setShowModalBox(true);
                  }}
                >
                  <i className="fas fa-plus" />
                  Log Hours
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModalBox && (
      <LogHoursModal
        showModalBox={showModalBox}
        setShowModalBox={setShowModalBox}
        modalValues={modalValues}
      />
      )}
    </>
  );
}

export default TaskLogHours;
