import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';

const apiHost = process.env.REACT_APP_API_HOST;

export const masterDataService = createApi({
  reducerPath: 'masterDataService',
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        return headers;
      }
      return {};
    },
  }),
  tagTypes: ['MasterData'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getMasterData: builder.query({
      query: () => ({
        url: '/master-data',
        method: 'get',
      }),
      providesTags: ['MasterData'],
    }),
    getMasterDataPoll: builder.query({
      query: () => ({
        url: '/master-data/poll',
        method: 'get',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const masterDataPollId = localStorage.getItem('masterDataPollId');
          if (!masterDataPollId || masterDataPollId !== data.data.pollId) {
            dispatch(masterDataService.util.invalidateTags(['MasterData']));
          }
          localStorage.setItem('masterDataPollId', data.data.pollId);
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),

  }),

});

export const {
  useGetMasterDataQuery,
  useGetMasterDataPollQuery,
} = masterDataService;
