import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fullName: '',
  initials: '',
  userId: '',
  emailId: '',
  role: {},
  client: {},
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const {
        fullName = '', emailId = '', userId = '', initials = '', role = {}, client = {},
      } = action.payload;
      Object.assign(state, {
        fullName,
        emailId,
        userId,
        initials,
        role,
        client,
      });
    },
    clearUser: (state) => {
      Object.assign(state, {
        fullName: '',
        initials: '',
        userId: '',
        emailId: '',
        role: {},
        client: {},
      });
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
