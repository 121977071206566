// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterBtn:hover {
    background: #f5ebd7 !important

}

.filterBtn{
    color: #997404 !important;
    background: #fff5e0 !important;
    border: 1px dashed rgba(240, 163, 26, 0.4) !important
}`, "",{"version":3,"sources":["webpack://./src/components/Reports/Report.css"],"names":[],"mappings":"AAAA;IACI;;AAEJ;;AAEA;IACI,yBAAyB;IACzB,8BAA8B;IAC9B;AACJ","sourcesContent":[".filterBtn:hover {\n    background: #f5ebd7 !important\n\n}\n\n.filterBtn{\n    color: #997404 !important;\n    background: #fff5e0 !important;\n    border: 1px dashed rgba(240, 163, 26, 0.4) !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
