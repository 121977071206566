import axiosInstance from './axios';

const axiosBaseQuery = ({ baseUrl, prepareHeaders = () => {} } = { baseUrl: `${process.env.REACT_APP_API_HOST}` }) => async (
  args,
  api,
) => {
  try {
    const {
      url, method, data, params, responseType,
    } = args;

    const headers = prepareHeaders({ getState: api?.getState });
    const result = await axiosInstance({
      url: baseUrl + url,
      method,
      data,
      params,
      headers,
      responseType,
    });
    return { data: result };
  } catch (axiosError) {
    return {
      error: {
        data: axiosError?.response?.data,
        status: axiosError?.response?.status,
        message: axiosError?.response?.data?.message,
      },
    };
  }
};

export default axiosBaseQuery;
