import { useState, useEffect } from 'react';
import MilestoneList from './MilestoneList';
import { useGetProjectMilestonesQuery } from '../../../Redux/services/project';

function Milestones({ projectId, mode, milestoneWiseTaskCount }) {
  const [milestoneList, setMilestoneList] = useState({
    todoMilestone: {
      id: 1, name: 'To Do', colorId: 1, editOnly: true, backgroundHexCode: '#f8d7da', textHexCode: '#721c24', badgeHexCode: '#dc3545',
    },
    completeMilestone: {
      id: 2, name: 'Completed', colorId: 2, editOnly: true, backgroundHexCode: '#d4edda', textHexCode: '#155724', badgeHexCode: '#28a745',
    },
    inprogressMilestone: [],
  });
  const [modalState, setModalState] = useState({ activeModal: null, modalValues: null });

  const {
    data: projectMilestones, isLoading, isError, error,
  } = useGetProjectMilestonesQuery(projectId, { skip: !projectId });
  if (isError) {
    window.toastr.error(error.message ?? 'Something went wrong!');
  }

  const openMilestoneList = () => setModalState({ activeModal: 'milestoneList', modalValues: null });
  const openMilestoneDetailsForm = (modalValues) => setModalState({ activeModal: 'milestoneDetailsForm', modalValues });
  const closeModal = () => {
    setModalState({ activeModal: null, modalValues: null });
    setMilestoneList({
      ...projectMilestones.data,
      todoMilestone: { ...projectMilestones.data.todoMilestone, editOnly: true },
      completeMilestone: { ...projectMilestones.data.completeMilestone, editOnly: true },
    });
  };

  useEffect(() => {
    if (projectMilestones?.data) {
      setMilestoneList({
        ...projectMilestones.data,
        todoMilestone: { ...projectMilestones.data.todoMilestone, editOnly: true },
        completeMilestone: { ...projectMilestones.data.completeMilestone, editOnly: true },
      });
    }
  }, [projectMilestones]);

  return (
    <>
      <button className="btn btn-sm btn-primary" type="button" onClick={openMilestoneList}>
        <i className="bi bi-flag-fill me-1" />Milestones
      </button>
      <MilestoneList
        mode={mode}
        milestoneWiseTaskCount={milestoneWiseTaskCount}
        isLoading={isLoading}
        projectId={projectId}
        show={modalState.activeModal === 'milestoneList'}
        onHide={closeModal}
        modalState={modalState}
        openMilestoneList={openMilestoneList}
        openMilestoneDetailsForm={openMilestoneDetailsForm}
        milestoneList={milestoneList}
        setMilestoneList={setMilestoneList}
      />
    </>
  );
}

export default Milestones;
