/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useGetProjectHistoryQuery } from '../../Redux/services/project';

function ProjectHistoryModal({ isOpen, onClose, projectId }) {
  const { data: projectHistory } = useGetProjectHistoryQuery(projectId);

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header className="pb-2" closeButton>
        <Modal.Title>
          <h2>Project History</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-4" style={{ maxHeight: 'calc(50vh - 100px)', overflowY: 'auto' }}>
        <div className="timeline">
          {projectHistory?.data?.history.map((historyItem, index) => (
            // eslint-disable-next-line
            <div key={index} className="timeline-item">
              <div className="timeline-line w-20px" />
              <div className="timeline-icon symbol symbol-circle symbol-20px">
                <div className="symbol-label bg-light">
                  <i className="fa-solid fa-circle-dot fs-9" />
                </div>
              </div>
              <div className="timeline-content">
                <div className="pe-3 mb-0">
                  {/* eslint-disable-next-line */}
                  <div className="fs-5 fw-semibold mb-0" dangerouslySetInnerHTML={{ __html: historyItem.change }} />
                  <div className="d-flex flex-stack align-items-center mt-0 fs-6">
                    <div className="text-muted me-2 fs-7">{historyItem.timestamp} by <span className="text-primary">{historyItem.byUser.name}</span></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ProjectHistoryModal;
