import { useState } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useGlobalSearchQuery } from '../../Redux/services/globalSearch';
import useDebounce from '../custom/useDebounce';

function GlobalSearch() {
  const [keyword, setKeyword] = useState('');
  const debouncedValue = useDebounce({ value: keyword, delay: 500 });
  const { data: globalSearchData, isFetching } = useGlobalSearchQuery({
    data: { keyword: debouncedValue },
  });

  return (
    <div className="d-flex align-items-stretch ms-2 ms-lg-3">
      <div id="kt_header_search" className="header-search d-flex align-items-stretch" data-kt-search-keypress="true" data-kt-search-min-length={2} data-kt-search-enter="enter" data-kt-search-layout="menu" data-kt-menu-trigger="auto" data-kt-menu-overflow="false" data-kt-menu-permanent="true" data-kt-menu-placement="bottom-end">
        <div
          className="d-flex align-items-center"
          data-kt-search-element="toggle"
          id="kt_header_search_toggle"
        >
          <div
            className="btn btn-icon btn-active-light-primaryw-35px h-35px w-md-40px h-md-40px"
          >
            <span className="svg-icon svg-icon-1">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="17.0365"
                  y="15.1223"
                  width="8.15546"
                  height="2"
                  rx="1"
                  transform="rotate(45 17.0365 15.1223)"
                  fill="currentColor"
                />
                <path
                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </div>
        </div>
        <div
          data-kt-search-element="content"
          className="menu menu-sub menu-sub-dropdown p-6 w-475px w-md-475px"
        >
          <div data-kt-search-element="wrapper">
            <form
              data-kt-search-element="form"
              className="w-100 position-relative mb-3"
              autoComplete="off"
            >
              <span
                className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="17.0365"
                    y="15.1223"
                    width="8.15546"
                    height="2"
                    rx="1"
                    transform="rotate(45 17.0365 15.1223)"
                    fill="currentColor"
                  />
                  <path
                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <input className="search-input form-control form-control-flush ps-10 search-user-input" onChange={(e) => setKeyword(e.target.value)} name="keyword" defaultValue="" placeholder="Search Projects, Tasks or Users..." data-kt-search-element="input" />
              {
                isFetching
                && (
                <span
                  className="search-spinner position-absolute top-50 end-0 translate-middle-y lh-0  me-1"
                >
                  <span className="spinner-border h-15px w-15px align-middle text-gray-400" />
                </span>
                )
            }
            </form>
            <div className="separator border-gray-200 mb-6" />
            <div className="mb-5" data-kt-search-element="main">
              <div className="scroll-y mh-200px mh-lg-325px">
                {globalSearchData?.data?.users?.map((user) => (
                  <div key={user?.id} className="d-flex align-items-center mb-5">
                    <div className="symbol symbol-40px me-4">
                      <span className="symbol-label bg-light">
                        <span className="svg-icon svg-icon-2 svg-icon-primary">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="currentColor" />
                            <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="currentColor" />
                          </svg>
                        </span>

                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id="button-tooltip">{user?.name}</Tooltip>}
                      >
                        <div style={{ maxWidth: '250px' }} className="text-truncate">
                          <Link
                            to="/users"
                            className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                            state={user}
                          >{user?.name}
                          </Link>
                        </div>
                      </OverlayTrigger>
                      <span className="fs-7 text-muted fw-semibold">User</span>
                    </div>
                  </div>
                ))}
                {globalSearchData?.data?.projects?.map((project) => (
                  <div key={project?.id} className="d-flex align-items-center mb-5">
                    <div className="symbol symbol-40px me-4">
                      <span className="symbol-label bg-light">
                        <span className="svg-icon svg-icon-2 svg-icon-primary">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M11 11H13C13.6 11 14 11.4 14 12V21H10V12C10 11.4 10.4 11 11 11ZM16 3V21H20V3C20 2.4 19.6 2 19 2H17C16.4 2 16 2.4 16 3Z" fill="currentColor" />
                            <path d="M21 20H8V16C8 15.4 7.6 15 7 15H5C4.4 15 4 15.4 4 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z" fill="currentColor" />
                          </svg>
                        </span>

                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id="button-tooltip">{project?.name}</Tooltip>}
                      >
                        <div style={{ maxWidth: '250px' }} className="text-truncate">
                          <Link
                            to={`/projectDetails/${project?.id}`}
                            className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                          >{project?.name}
                          </Link>
                        </div>
                      </OverlayTrigger>

                      <span className="fs-7 text-muted fw-semibold">Project</span>
                    </div>
                  </div>
                ))}

                {globalSearchData?.data?.tasks?.map((task) => (
                  <div key={task?.id} className="d-flex align-items-center mb-5">
                    <div className="symbol symbol-40px me-4">
                      <span className="symbol-label bg-light">
                        <span className="svg-icon svg-icon-2 svg-icon-primary">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="currentColor" />
                            <path d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id="button-tooltip">{task?.uniqueTaskId}</Tooltip>}
                      >
                        <div style={{ maxWidth: '250px' }} className="text-truncate">
                          <Link
                            to={`/tasks/${task?.uniqueTaskId}`}
                            className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                          >{task?.title}
                          </Link>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                ))}
                {!globalSearchData?.data?.tasks?.length
              && !globalSearchData?.data?.users?.length && !globalSearchData?.data?.projects?.length
             && (
             <div className="pb-15 fw-semibold">
               <h3 className="text-gray-600 fs-5 mb-2">No result found</h3>
               <div className="text-muted fs-7">Please try again with a different query
               </div>
             </div>
             )}
              </div>
            </div>
            <div data-kt-search-element="empty" className="text-center d-none">
              <div className="pt-10 pb-10">
                <span className="svg-icon svg-icon-4x opacity-50">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z"
                      fill="currentColor"
                    />
                    <path
                      d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z"
                      fill="currentColor"
                    />
                    <rect
                      x="13.6993"
                      y="13.6656"
                      width="4.42828"
                      height="1.73089"
                      rx="0.865447"
                      transform="rotate(45 13.6993 13.6656)"
                      fill="currentColor"
                    />
                    <path
                      d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlobalSearch;
