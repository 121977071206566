import { useGetTaskActivityLogQuery } from '../../../Redux/services/task';

function TaskActivityLog({ id }) {
  const { data: activityLogData } = useGetTaskActivityLogQuery({ id });

  return (
    <div className="col-12 mt-6">
      <div className="card card-flush h-lg-100">
        <div className="card-header py-4 minimize">
          <p className="card-title align-items-start flex-column">
            <span className="fw-bold h4">Task History</span>
            <span className="text-muted mt-1 fw-semibold fs-6">History of activity on this Task</span>
          </p>
          <div className="card-toolbar mt-0">
            <button
              aria-label="hidden"
              type="button"
              className="btn btn-icon btn-sm btn-light-primary justify-content-center minimize"
            >
              <i className="fas fa-plus" />
            </button>
          </div>
        </div>
        <div className="card-body collapse">
          <div className="m-0">
            <div className="timeline" style={{ maxHeight: '400px', overflow: 'auto' }}>
              <div className="timeline-item">
                <div className="timeline-icon symbol symbol-circle symbol-20px" />
              </div>
              {activityLogData?.data?.map((item) => (
                <div key={item?.date}>
                  <div className="timeline-item">
                    <div className="timeline-line h-100" style={{ marginLeft: '.68rem' }} />
                    <div className="timeline-icon symbol symbol-circle symbol-20px">
                      <div className="symbol-label bg-light">
                        <i className="fa-solid fa-circle-dot fs-9" />
                      </div>
                    </div>
                    <div className="timeline-content">
                      <div className="fs-3 fw-semibold text-primary mb-0">{item.date ?? ''}</div>
                      {item?.history?.map((data, index) => (
                        // eslint-disable-next-line
                        <div key={index}>
                          <div className="separator separator-dashed my-3" />
                          <div className="pe-3 mb-0">
                            <div className="overflow-auto pb-0">
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-35px symbol-circle user-image" data-kt-initialized="1">
                                  <span className="fw-normal symbol-label solid bg-light-success text-success">{data?.byUser?.initials}</span>
                                </div>
                                <div className="ms-4">
                                  <div className="me-2 fs-4">
                                    {data?.changed?.length > 0
                                      ? data?.changed?.map((changedData, i) => (
                                        // eslint-disable-next-line
                                        <div key={i}>
                                          {/* eslint-disable-next-line */}
                                          <div className="fs-4" dangerouslySetInnerHTML={{ __html: changedData }} />
                                        </div>
                                      )) : data?.history}
                                  </div>
                                  <div className="d-flex align-items-center mt-0 fs-6">
                                    <div className="text-muted me-2 fs-7">
                                      {data?.timestamp} by <span className="text-primary">{data?.byUser?.name}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TaskActivityLog;
