import React from 'react';

function Footer() {
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      <div className="container-fluid d-flex flex-column flex-md-row flex-stack">

        <p className="text-gray-900 order-2 order-md-1">© 2024,
          <a href="https://minditsystems.com/" target="_blank" rel="noreferrer"> Mind IT Systems
          </a> All Rights Reserved.
        </p>
        <div className="text-dark order-2 order-md-1">
          <a
            href="https://minditsystems.com/"
            target="_blank"
            className="text-muted text-hover-primary fw-semibold me-2 fs-6 powered-image"
            rel="noreferrer"
          >
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/logos/logo-MindIT.png`}
              alt="Mind IT Systems"
              width="100px"
              className="theme-light-show"
            />
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/logos/logo-MindIT-white.png`}
              alt="Mind IT Systems"
              width="100px"
              className="theme-dark-show"
            />
          </a>
        </div>

      </div>

    </div>
  );
}
export default Footer;
