import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';

const apiHost = process.env.REACT_APP_API_HOST;

export const authService = createApi({
  reducerPath: 'authService',
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        return headers;
      }
      return {};
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        data,
        url: '/auth/login',
        method: 'post',
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: '/auth/forgot-password',
        method: 'post',
        data: email,
      }),
    }),
  }),

});

export const { useLoginMutation, useForgotPasswordMutation } = authService;
