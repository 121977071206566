import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGetClientConfigurationsQuery, useUpdateClientConfigurationsMutation } from '../../Redux/services/company';

const featureOrder = ['Timesheet', 'Reports'];
function SettingsModal({ onHide, id }) {
  const { data } = useGetClientConfigurationsQuery({ id });
  const [configurationId, setConfigurationId] = useState(null);
  const [updateClientConfigurations, { isLoading }] = useUpdateClientConfigurationsMutation({ id });

  const handlePermissionChange = async (e, featureId) => {
    setConfigurationId(featureId);
    try {
      const reqBody = {
        id: featureId,
        status: e.target.checked,
      };
      const response = await updateClientConfigurations({ data: reqBody, id });
      if (response?.data?.success) {
        window.toastr.success(response.data.message);
      } else {
        window.toastr.error(response?.error?.data?.message ?? 'Something went wrong!');
      }
    } catch (error) {
      window.toastr.error(error?.data?.message ?? 'Something went wrong!');
    }
    setConfigurationId(null);
  };

  return (
    <Modal show centered onHide={onHide} size="lg">

      <Modal.Header className="pb-2" closeButton>
        <Modal.Title>
          <h2>Feature Access Manager</h2>
        </Modal.Title>
      </Modal.Header>
      <div className="modal-body  mb-4">
        <h5 className="mb-6">Permissions</h5>

        {data?.data?.slice()
          .sort((a, b) => featureOrder.indexOf(a.featureName) - featureOrder.indexOf(b.featureName))
          .map((feature) => (
            <div key={feature?.featureId} className="d-flex flex-stack border border-gray-300 border-dashed rounded py-6 ps-6 mt-4">
              <div className="d-flex align-items-center me-5">

                <div className="me-5">
                  <Link className="fw-bold text-gray-800 text-hover-primary fs-7">{feature?.featureName}</Link>
                </div>
              </div>
              <div className="text-gray-500 fw-bold fs-7 text-end">
                <div className="form-check form-switch form-check-custom form-check-solid me-10">
                  {(isLoading && configurationId === feature?.featureId) && <span className="me-6 spinner-border spinner-border-sm align-middle ms-2" />}
                  <input
                    key={feature?.featureName}
                    onChange={(e) => handlePermissionChange(e, feature?.featureId)}
                    className="form-check-input h-20px w-30px cursor-pointer"
                    type="checkbox"
                    checked={feature?.status}
                  />
                </div>

              </div>
            </div>
          ))}
      </div>

    </Modal>
  );
}

export default SettingsModal;
