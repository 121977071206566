import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useForgotPasswordMutation } from '../../Redux/services/auth';

const publicURL = process.env.REACT_APP_PUBLIC_URL;
function ForgotPassword() {
  const navigate = useNavigate();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const tokenFromStorage = localStorage.getItem('token');

  useEffect(() => {
    if (tokenFromStorage) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    const response = await forgotPassword({
      emailId: data.email,
    });
    if (response?.data?.success) {
      window.toastr.success(response.data.message);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    }
    if (response?.error) {
      if (response?.error?.status === 400) {
        window.toastr.error('Email does not exist. Please try again with a valid email');
      } else {
        // Network error
        window.toastr.error(response?.error?.message);
      }
    }
  };
  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-xl-row flex-column-fluid">
        <div className="d-flex flex-column flex-lg-row-fluid">
          <div className="d-flex flex-row-fluid flex-center p-10 pt-20 pb-0">
            <div className="d-flex flex-column">
              <Link to="/" className="mb-7">
                <img alt="Logo" src={`${publicURL}/assets/media/logos/workeazy-light-logo.png`} className="h-70px theme-light-show" />
                <img
                  alt="Logo"
                  src={`${publicURL}/assets/media/logos/workeazy-dark-logo.png`}
                  className="h-70px theme-dark-show"
                />
              </Link>
              <p className="fw-normal mb-2 text-muted" style={{ fontSize: '30px' }}>Your tool to</p>
              <h1 className=" mb-2" style={{ fontSize: '35px', color: '#02638E' }}>Manage your work in one place</h1>
            </div>
          </div>
          <div
            className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-xl-300px mb-xl-0 theme-light-show"
            style={{ backgroundImage: `url(${publicURL}/assets/media/login-img/login.png)`, marginLeft: '-8rem' }}
          />
          <div
            className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-xl-300px mb-xl-0 theme-dark-show"
            style={{ backgroundImage: `url(${publicURL}/assets/media/login-img/login-dark.png)`, marginLeft: '-8rem' }}
          />
          <div className="d-flex mt-2 flex-center mb-20">
            <img
              src={`${publicURL}/assets/media/login-img/mind-it-systems.png`}
              alt=""
              className="w-125px theme-light-show"
              width="100%"
              style={{ marginLeft: '-10rem' }}
            />
            <img
              src={`${publicURL}/assets/media/login-img/mind-it-systems-white.png`}
              alt=""
              className="w-125px theme-dark-show"
              width="100%"
              style={{ marginLeft: '-10rem' }}
            />
          </div>
        </div>
        <div className="flex-row-fluid d-flex flex-center justfiy-content-xl-first p-10">
          <div className=" card d-flex flex-center p-15 shadow-sm rounded-3 w-100 w-md-400px ms-xl-10">
            <form onSubmit={handleSubmit(onSubmit)} className="form w-100" id="kt_free_trial_form">
              <div className="text-center mb-10 mt-10">
                <div className="d-flex flex-row-fluid flex-center mb-5">
                  <img src={`${publicURL}/assets/media/logos/logo.png`} alt="" className="w-70px theme-light-show" />
                  <img src={`${publicURL}/assets/media/logos/logo-white.png`} alt="" className="w-70px theme-dark-show" />
                </div>
                <h1 className="text-dark mb-5 mt-10">Forgot Password ?</h1>
                <div className="text-gray-600 fw-semibold fs-4">Enter your email to reset your password.</div>
              </div>
              <div className="fv-row mb-10">
                <label htmlFor="email" className="form-label fw-bold text-gray-900 fs-6 required">Email</label>
                <input id="email" {...register('email', { required: { value: true, message: 'Email is required' }, pattern: { value: /^\S+@\S+\.\S+$/, message: 'Please insert a valid email address' } })} className="form-control" type="text" placeholder="" name="email" />
                {errors?.email && <div className="text-danger small"> {errors.email?.message} </div>}
              </div>
              <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                <button type="submit" id="kt_password_reset_submit" className="btn btn-lg btn-primary fw-bold me-4">
                  { !isLoading ? <span className="indicator-label">Submit</span>
                    : (
                      <span>
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    )}
                </button>
                <button type="button" onClick={() => navigate('/login')} className="btn btn-lg btn-light-primary fw-bold">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
