import moment from 'moment';
import { useState } from 'react';
import Select from 'react-select';
import { userRoleConstants, userStatusConstants } from '../../utils/constant';

const renderLogDate = () => {
  const logDate = [
    { label: 'January', value: '1' },
    { label: 'February', value: '2' },
    { label: 'March', value: '3' },
    { label: 'April', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'August', value: '8' },
    { label: 'September', value: '9' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ];

  return logDate;
};

const customSelectStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '500',
    opacity: '.7',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '1.15rem',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    borderRadius: '1.15rem',
    backgroundColor: 'var(--bs-dropdown-bg)',
    fontSize: '13px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'var(--bs-dropdown-bg)',
    color: state.isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
    ':hover': {
      backgroundColor: 'var(--bs-component-hover-bg)',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--bs-gray-300)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
    fontSize: '1.1rem',
  }),
};

function TableHeader({
  masterData, roleMemo, setFiltersData,
}) {
  const [filters, setFilters] = useState({ month: renderLogDate().find((option) => option.value === moment().format('M')) });

  const renderUsers = () => masterData?.data?.users
    ?.filter((user) => (user.StatusId === userStatusConstants.ACTIVE))
    .map((user) => ({
      label: user?.fullName,
      value: user?.id,
    }));

  const renderProjects = () => masterData?.data?.projects?.map((project) => ({
    label: project?.name,
    value: project?.id,
  }));

  const handleFilter = () => {
    const filterBody = {};
    if (filters?.users?.length > 0) {
      filterBody.users = filters?.users?.map((user) => user?.value);
    }
    filterBody.projects = filters?.projects?.map((project) => project?.value);
    filterBody.month = filters?.month?.value;
    setFiltersData(filterBody);
  };

  const handleClear = () => {
    setFilters({ month: renderLogDate().find((option) => option.value === moment().format('M')), users: [], projects: [] });
    setFiltersData({ month: moment().format('M') });
  };

  const handleSelectChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    return null;
  };

  return (
    <div className="card-header d-block pt-3">
      <h5 className="card-title mb-3">Timesheet</h5>
      <div className="row pb-4 gap-3 gap-md-0">
        <div className="col-md-2">
          <Select
            options={renderProjects()}
            components={{
              IndicatorSeparator: () => null,
            }}
            isMulti
            placeholder="Select Project"
            value={filters?.projects}
            onChange={(e) => handleSelectChange('projects', e)}
            styles={customSelectStyles}
          />
        </div>
        <div className="col-md-2">
          <Select
            options={renderLogDate()}
            isMulti={false}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
            }}
            placeholder="Select Log Month"
            isClearable
            onChange={(e) => handleSelectChange('month', e)}
            value={filters?.month}
            defaultValue={renderLogDate().find((option) => option.value === moment().format('M'))}
            styles={customSelectStyles}
          />

        </div>
        { (roleMemo?.id !== userRoleConstants.USER) ? (
          <div className="col-md-2">
            <Select
              options={renderUsers()}
              isMulti
              components={{
                IndicatorSeparator: () => null,
              }}
              isClearable
              value={filters?.users}
              onChange={(e) => handleSelectChange('users', e)}
              styles={customSelectStyles}
              placeholder="Select Users"
            />
          </div>
        ) : null}

        <div className="col-md-2">
          <button
            type="button"
            onClick={handleFilter}
            className="btn ntn-sm btn-secondary me-sm-3 me-1"
          >Filter
          </button>
          <button
            type="button"
            onClick={handleClear}
            className="btn btn-label-secondary"
          >Clear
          </button>
        </div>
      </div>
    </div>
  );
}

export default TableHeader;
