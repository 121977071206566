import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useGetMasterDataQuery } from '../../../Redux/services/masterData';
import { useGetUsersListWithFiltersQuery } from '../../../Redux/services/user';
import Pagination from '../../custom/Pagination';
import AddUserModal from '../AddUserModal';
import EditUserModal from '../EditUserModal';
import { decryptPassword, decryptRole } from '../../../utils/cryptoUtils';
import { roleHierarchyMapper, userRoleConstants } from '../../../utils/constant';

const customSelectStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '500',
    opacity: '.7',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '1.15rem',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    borderRadius: '1.15rem',
    backgroundColor: 'var(--bs-dropdown-bg)',
    fontSize: '13px',
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: 'var(--bs-dropdown-bg)',
    color: 'var(--bs-gray-700)',
    ':hover': {
      backgroundColor: 'var(--bs-component-hover-bg)',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--bs-gray-300)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
    fontSize: '1.1rem',
  }),
};

function UsersList() {
  const { state } = useLocation();
  const { data: masterData } = useGetMasterDataQuery();
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setOrderSort] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [isEditable, setIsEditable] = useState(true);
  const size = 5;
  const roleMemo = useMemo(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    return roleDecrypted;
  }, []);
  const [filters, setFilters] = useState({ name: state?.name, role: roleMemo?.id === userRoleConstants.ADMIN ? userRoleConstants.USER : '' });
  const { data: users, error } = useGetUsersListWithFiltersQuery({
    filters, sortColumn, sortOrder, page: currentPage, size,
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: state ? {
        label: state.name,
        value: state.name,
      } : '',
      email: '',
      phoneNo: '',
      role: roleMemo?.id === userRoleConstants.ADMIN ? {
        label: 'User',
        value: userRoleConstants.USER,
      } : '',
      status: [],
    },
  });

  useEffect(() => {
    if (state?.name) {
      setFilters((prev) => ({ ...prev, name: state.name }));
      setValue('name', {
        label: state.name,
        value: state.name,
      });
    }
  }, [state?.name, setValue]);

  const handleColumnSort = (columnName) => {
    let orderBy = 'asc';
    if (sortColumn === columnName) {
      orderBy = sortOrder === 'asc' ? 'desc' : 'asc';
    }
    setOrderSort(orderBy);
    setSortColumn(columnName);
    setCurrentPage(1);
  };

  const closeAddUserModal = () => {
    setShowAddUserModal(false);
  };

  const handleShowEditUserModal = ({ user = {}, canEdit = true, event }) => {
    if (event) {
      event.stopPropagation();
    }
    const { password } = user;
    if (password) {
      const decryptedPassword = decryptPassword(password);
      const userDetails = {
        ...user,
        password: decryptedPassword,
      };
      setIsEditable(canEdit);
      setUserData(userDetails);
      setShowEditUserModal(true);
    }
  };

  const closeEditUserModal = () => {
    setShowEditUserModal(false);
  };

  const onSubmit = async (filterData) => {
    const {
      name, email, phoneNo, role, status,
    } = filterData;
    const filterObj = {};
    if (name?.value) {
      filterObj.name = name?.value;
    }
    if (email?.value) {
      filterObj.email = email?.value;
    }
    if (phoneNo?.value) {
      filterObj.phoneNo = phoneNo?.value;
    }
    if (role?.value) {
      filterObj.role = role?.value;
    }
    if (status.length > 0) {
      const statusIds = filterData?.status?.map((userStatus) => userStatus.value);
      filterObj.status = statusIds;
    }
    setFilters(filterObj);
    setCurrentPage(1);
  };

  const handleClear = () => {
    if (roleMemo?.id === userRoleConstants.ADMIN) {
      reset({
        name: '',
        email: '',
        phoneNo: '',
        status: [],
      });
      setFilters({ role: userRoleConstants.USER });
    } else {
      reset({
        name: '',
        email: '',
        phoneNo: '',
        role: '',
        status: '',
      });
      setFilters({});
    }
    setCurrentPage(1);
    setSortColumn('name');
    setOrderSort('asc');
  };

  const renderNames = () => (roleMemo?.id === userRoleConstants.ADMIN ? masterData?.data?.users
    ?.filter((user) => (user?.RoleId === userRoleConstants.USER))
    .map((user) => ({
      label: user?.fullName,
      value: user?.fullName,
    }))
    : masterData?.data?.users
      ?.map((user) => ({
        label: user?.fullName,
        value: user?.fullName,
      })));
  const renderEmails = () => (roleMemo?.id === userRoleConstants.ADMIN ? masterData?.data?.users
    ?.filter((user) => (user?.RoleId === userRoleConstants.USER))
    .map((user) => ({
      label: user?.email,
      value: user?.email,
    }))
    : masterData?.data?.users
      ?.map((user) => ({
        label: user?.email,
        value: user?.email,
      })));

  const renderPhoneNo = () => {
    const usersWithPhoneNumbers = (roleMemo?.id === userRoleConstants.ADMIN
      ? masterData?.data?.users
        ?.filter((user) => ((user?.RoleId === userRoleConstants.USER) ? user?.phoneNo : false))
      : masterData?.data?.users
        ?.filter((user) => user?.phoneNo)) ?? [];
    const phoneNumbers = usersWithPhoneNumbers?.map((user) => ({
      label: user?.phoneNo,
      value: user?.phoneNo,
    }));
    return phoneNumbers;
  };
  const renderRoles = () => masterData?.data?.roles?.map((userRole) => ({
    label: roleHierarchyMapper[userRole?.name],
    value: userRole?.id,
  }));

  const renderStatus = () => masterData?.data?.userStatuses?.map((userStatus) => ({
    label: userStatus?.name,
    value: userStatus?.id,
  }));

  useEffect(() => {
    if (users?.data) {
      setTotalCount(users?.data?.totalRecords);
    }
    // eslint-disable-next-line
  }, [users?.data?.totalRecords]);

  if (error) {
    window.toastr.error('Something went wrong!');
  }

  return (
    <>
      <div className="row pt-0 mt-5">
        <div className="col-md-12">
          <div className="d-flex justify-content-between">
            <div>
              <h2 className="pb-0 mb-0 ms-0 pt-0">Users</h2>
              <ul className="breadcrumb fw-semibold fs-base mb-1">
                <li className="breadcrumb-item text-muted">
                  <Link to="/" className="text-muted text-hover-primary">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item text-gray-900">
                  Users
                </li>
              </ul>
            </div>
            <div>
              <button
                onClick={() => setShowAddUserModal(true)}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i
                  className="fa fa-plus fa-plus me-1"
                />Add
              </button>
            </div>
          </div>
          <div className="card priority-card mt-4">

            <form className="card-header d-block pt-3" onSubmit={handleSubmit(onSubmit)}>
              <h5 className="card-title mb-3">Search Filter</h5>
              <div className="row pb-2 g-4">

                <div className="col-md-2">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={renderNames()}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        styles={customSelectStyles}
                        placeholder="Full Name"
                      />
                    )}
                  />
                </div>
                <div className="col-md-2">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={renderEmails()}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        styles={customSelectStyles}
                        placeholder="Email"
                      />
                    )}
                  />
                </div>
                <div className="col-md-2">
                  <Controller
                    name="phoneNo"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        options={renderPhoneNo()}
                        styles={customSelectStyles}
                        placeholder="Phone No."
                      />
                    )}
                  />
                </div>
                <div className="col-md-2">
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        styles={customSelectStyles}
                        isDisabled={roleMemo?.id === userRoleConstants.ADMIN}
                        components={{ IndicatorSeparator: () => null }}
                        options={renderRoles()}
                        placeholder="Role"
                      />
                    )}
                  />
                </div>
                <div className="col-md-2">
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        styles={customSelectStyles}
                        components={{ IndicatorSeparator: () => null }}
                        isMulti
                        options={renderStatus()}
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
                <div className="col-md-2">
                  <button
                    id="filter-button"
                    type="submit"
                    className="btn ntn-sm btn-secondary me-sm-3 me-1"
                  >Filter
                  </button>
                  <button
                    onClick={handleClear}
                    type="button"
                    className="btn btn-label-secondary"
                  >Clear
                  </button>
                </div>
              </div>
            </form>
            <div className="card-body p-4 py-2 table-responsive">

              <table
                className="table masterData-table align-middle table-row-dashed fs-6 gy-5 dataTable"
                id="kt_ecommerce_sales_table"
              >
                <thead>
                  <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th className="sortable" style={{ width: '200px' }} onClick={() => handleColumnSort('name')}>
                      <span role="button">Full Name</span>
                      {sortColumn === 'name' && (
                        <span className="sorting-icons m-2">
                          <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                          <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                        </span>
                      )}
                    </th>
                    <th className="sortable" style={{ width: '120px' }} onClick={() => handleColumnSort('phoneNo')}>
                      <span role="button">Phone No.</span>
                      {sortColumn === 'phoneNo' && (
                        <span className="sorting-icons m-2">
                          <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                          <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                        </span>
                      )}
                    </th>
                    <th className="sortable" style={{ width: '120px' }} onClick={() => handleColumnSort('role')}>
                      <span role="button">Role</span>
                      {sortColumn === 'role' && (
                        <span className="sorting-icons m-2">
                          <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                          <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                        </span>
                      )}
                    </th>
                    <th className="sortable" style={{ width: '100px' }} onClick={() => handleColumnSort('status')}>
                      <span role="button">Status</span>
                      {sortColumn === 'status' && (
                        <span className="sorting-icons m-2">
                          <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                          <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                        </span>
                      )}
                    </th>
                    <th className="sortable" style={{ width: '100px' }}>Actions</th>

                  </tr>
                </thead>
                <tbody className="fw-semibold text-gray-600">
                  {users?.data?.users?.map((user) => (
                    <tr
                      key={user.id}
                      onClick={() => handleShowEditUserModal({ user, canEdit: false })}
                    >
                      <td className="d-flex align-items-center">
                        <div
                          className="symbol symbol-circle symbol-50px overflow-hidden me-3"
                        >
                          <Link to="/users">
                            <div
                              className="symbol-label solid fs-3 bg-light-success text-success fw-normal"
                            >
                              {user?.initials}
                            </div>
                          </Link>
                        </div>
                        <div className="d-flex flex-column">
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 300 }}
                            overlay={<Tooltip id="button-tooltip">{user?.name}</Tooltip>}
                          >
                            <div style={{ maxWidth: '250px' }} className="text-truncate">
                              <Link
                                to="/users"
                                className="text-gray-800 text-hover-primary mb-1 text-truncate"
                              >{user?.name}
                              </Link>
                            </div>
                          </OverlayTrigger>
                          <span>{user?.email}</span>
                        </div>
                      </td>
                      <td style={{ width: '120px' }}>{user?.phoneNo ? user?.phoneNo : '---'}</td>
                      <td style={{ width: '120px' }}>{roleHierarchyMapper[user?.role?.name]}</td>
                      <td style={{ width: '100px' }}>
                        <div className={`badge ${user?.status?.id === 1 ? 'badge-light-warning' : 'badge-light-dark'} fw-bold`}>{user?.status?.name}
                        </div>
                      </td>
                      {/* eslint-disable-next-line */}
                      <td style={{ width: '100px' }}>
                        <Link onClick={(event) => handleShowEditUserModal({ user, canEdit: true, event })}><i className="fa fa-edit me-3" /></Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {users?.data?.users?.length > 0
                ? (
                  <Pagination
                    dataPerPage={size}
                    totalData={totalCount}
                    currentPage={currentPage}
                    paginate={setCurrentPage}
                  />
                )
                : <div className="text-center fw-bold mx-auto m-4">No Records Found!</div>}
            </div>
          </div>
        </div>
      </div>

      {showAddUserModal && (
        <AddUserModal
          show={showAddUserModal}
          onHide={closeAddUserModal}
          masterData={masterData}
        />
      )}
      {showEditUserModal
        && (
          <EditUserModal
            show={showEditUserModal}
            onHide={closeEditUserModal}
            masterData={masterData}
            userData={userData}
            isEditable={isEditable}
          />
        )}
    </>
  );
}

export default UsersList;
