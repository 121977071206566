// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-bs-theme=dark] .table-container-1 th:nth-of-type(1),
[data-bs-theme=dark] .table-container-1 td:nth-of-type(1) {

    background-color: #383846;
}

[data-bs-theme=dark] .table-container-1 th:nth-of-type(2),
[data-bs-theme=dark] .table-container-1 td:nth-of-type(2) {

    background-color: #383846;
}

.table-container-1 th:nth-of-type(1),
.table-container-1 td:nth-of-type(1) {
    background-color: #fbfbfb;
}

.table-container-1 th:nth-of-type(2),
.table-container-1 td:nth-of-type(2) {
    background-color: #fbfbfb;
}
`, "",{"version":3,"sources":["webpack://./src/components/Timesheet/Table.css"],"names":[],"mappings":"AAAA;;;IAGI,yBAAyB;AAC7B;;AAEA;;;IAGI,yBAAyB;AAC7B;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;;IAEI,yBAAyB;AAC7B","sourcesContent":["[data-bs-theme=dark] .table-container-1 th:nth-of-type(1),\n[data-bs-theme=dark] .table-container-1 td:nth-of-type(1) {\n\n    background-color: #383846;\n}\n\n[data-bs-theme=dark] .table-container-1 th:nth-of-type(2),\n[data-bs-theme=dark] .table-container-1 td:nth-of-type(2) {\n\n    background-color: #383846;\n}\n\n.table-container-1 th:nth-of-type(1),\n.table-container-1 td:nth-of-type(1) {\n    background-color: #fbfbfb;\n}\n\n.table-container-1 th:nth-of-type(2),\n.table-container-1 td:nth-of-type(2) {\n    background-color: #fbfbfb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
