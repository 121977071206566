/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
/* eslint-disable no-shadow */

import { useState, useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import Chart from 'react-apexcharts';
import CountUp from 'react-countup';
import Swal from 'sweetalert2';
import moment from 'moment';
import UserModal from './UserModal';
import AddTask from '../Tasks/AddTaskModal';
import Milestones from './Milestones';
import ProjectHistoryModal from './ViewHistoryModal';
import UpdateProjectModal from './UpdateProjectModal';
import { useGetProjectByIdQuery, useUpdateCompletedProjectStatusMutation } from '../../Redux/services/project';
import TaskTable from './TaskTable';
import { decryptRole } from '../../utils/cryptoUtils';
import { projectStatusConstants, userRoleConstants } from '../../utils/constant';
import { useGetLoggedUserQuery } from '../../Redux/services/user';

function ProjectDetails() {
  const { projectName } = useParams();
  const [show, setShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [decryptedRole, setDecryptedRole] = useState('');
  const [updateCompletedProjectStatus] = useUpdateCompletedProjectStatusMutation();
  const { data: loggedUser } = useGetLoggedUserQuery();

  const handleEditModal = () => setShowUpdateModal(true);
  const handleHistoryModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);
  const handleUserShow = () => setShowUserModal(true);
  const handleUserClose = () => setShowUserModal(false);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleUserShow();
    }
  };

  const roleMemo = useMemo(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    return roleDecrypted;
  }, []);

  const handleClick = () => {
    localStorage.setItem('projectName', projectName);
  };

  useEffect(() => {
    setTimeout(() => {
      window.KTMenu.init();
    }, 500);

    const role = localStorage.getItem('role');
    const decryptedRole = role ? decryptRole(role) : '';
    setDecryptedRole(decryptedRole);
  }, []);

  const closeAddTaskModal = ({ reOpen }) => {
    setShow(false);
    if (reOpen) {
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  };
  const handleShow = () => setShow(true);

  const handleCloseUpdateModal = () => setShowUpdateModal(false);

  const { data: projectData, error } = useGetProjectByIdQuery(projectName);

  if (error) {
    window.toastr.error('Something went wrong');
  }
  if (!projectData) return null;

  const {
    id,
    name,
    startDate,
    endDate,
    owner,
    users,
    daysToGo,
    taskCount,
    totalTask,
    tasksOverdue,
    progressPercentage,
    status,
    progress,
    milestoneWiseTaskCount,
  } = projectData.data.projectStats;

  const handleCompletedStatus = () => {
    const stateMomentDate = moment(projectData?.data?.projectStats?.startDate, 'YYYY-MM-DD');
    if (moment().isBefore(stateMomentDate)) {
      Swal.fire({
        text: 'This project cannot be marked as completed before the start date.',
        icon: 'warning',
        iconColor: 'var(--bs-primary)',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Close',
        heightAuto: false,
        customClass: {
          cancelButton: 'btn btn-sm btn-outline-secondary ms-3',
        },
        buttonsStyling: false,
      });
      return;
    }
    const markasCompleteModal = () => {
      Swal.fire({
        text: 'On marking the project as completed, you will not be able to further edit its details. Would you like to proceed?',
        icon: 'warning',
        iconColor: 'var(--bs-primary)',
        showCancelButton: true,
        heightAuto: false,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-sm btn-secondary bg-dark',
          cancelButton: 'btn btn-sm btn-outline-secondary ms-3',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await updateCompletedProjectStatus({
            projectId: projectName,
          });
          if (response?.data?.success) {
            window.toastr.success(response.data.message);
          }
        }
      });
    };

    markasCompleteModal();
  };

  const renderDonutChart = (taskCount) => {
    const taskCounts = Object.values(taskCount);

    const options = {
      chart: {
        type: 'donut',
      },
      labels: Object.keys(taskCount),
      colors: ['#FF0000', 'rgb(255, 153, 0)', '#47BE7D'],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      }],
    };

    return <Chart options={options} series={taskCounts} type="donut" width="150" />;
  };

  return (
    <>
      <div className="d-flex justify-content-between progress-button mt-5">
        <div>
          <h2 className="pb-0 mb-0 ms-0 pt-0">Project details</h2>
          <ul className="breadcrumb fw-semibold fs-base mb-1">
            <li className="breadcrumb-item text-muted">
              <Link to="/" className="text-muted text-hover-primary">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item text-muted">
              <Link to="/projects" className="text-muted text-hover-primary">
                Projects
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-900">
              Project details
            </li>
          </ul>
        </div>
        <div>
          {loggedUser?.data?.role.id !== userRoleConstants.USER && <Milestones mode="editMilestoneList" projectId={id} milestoneWiseTaskCount={milestoneWiseTaskCount} />}
          <Link to="/kanban" className="btn btn-sm btn-primary ms-2" role="button" onClick={handleClick}>
            <i className="fa-regular fa-window-restore me-1" />Kanban
          </Link>
        </div>
      </div>
      <div className="col-xl-12 col-lg-12 col-md-12">
        <div className="row mt-5 pt-0">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="row gy-3 g-xl-3">
              <div className="col-xl-5">
                <div className="card card-flush h-100">
                  <div className="card-header pb-0 container">
                    <div className="row justify-content-between w-100">
                      <h3 className="card-title align-items-start flex-column mb-0 col-8">
                        <div className="d-flex align-items-center mb-0">
                          <h1 className="fs-2 me-1 fw-bold" style={{ overflowWrap: 'anywhere' }}>
                            {name}
                          </h1>
                          <span className={`badge ${status?.id === projectStatusConstants.COMPLETED ? 'badge-light-info' : status?.name === 'Inactive' ? 'badge-light-dark' : progress?.name === 'On Time' ? 'badge-light-success' : 'badge-light-danger'} fs-base`}>
                            {status?.id === projectStatusConstants.COMPLETED ? 'Completed' : status?.id === projectStatusConstants.INACTIVE ? 'Inactive' : progress?.name}
                          </span>
                        </div>
                      </h3>
                      <div className="card-toolbar justify-content-end col-3 p-0">
                        {(decryptedRole?.id === userRoleConstants.SUPER_ADMIN
                          || (decryptedRole?.id === userRoleConstants.ADMIN && loggedUser?.data?.userId === owner?.id))
                          && (status?.id !== projectStatusConstants.COMPLETED)
                          && (
                            <span
                              className="btn-color-gray-400 btn-active-color-primary cursor-pointer"
                              title="Edit"
                              onClick={handleEditModal}
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                  handleEditModal();
                                }
                              }}
                              aria-label="Edit"
                            >
                              <i className="fa fa-edit fs-5 me-4" />
                            </span>
                          )}
                        <span
                          onClick={handleHistoryModal}
                          title="View History"
                          className="cursor-pointer"
                          role="button"
                          tabIndex="0"
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              handleHistoryModal();
                            }
                          }}
                          aria-label="View History"
                        >
                          <i className={`fa fa-history fs-5 ${(decryptedRole?.id !== userRoleConstants.USER && status?.id !== projectStatusConstants.COMPLETED) ? 'me-4' : ''}`} />
                        </span>
                        {((roleMemo?.id === userRoleConstants.SUPER_ADMIN) || (decryptedRole?.id === userRoleConstants.ADMIN && loggedUser?.data?.userId === owner?.id)) && (status?.id !== projectStatusConstants.COMPLETED) && (
                          <span
                            onClick={handleCompletedStatus}
                            title="Mark as Completed"
                            className="cursor-pointer"
                            role="button"
                            tabIndex="0"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                e.stopPropagation();
                                handleCompletedStatus();
                              }
                            }}
                            aria-label="Mark as Completed"
                          >
                            <i className="fa-regular fa-circle-check fs-5" />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-body py-2">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                      <div className="flex-grow-1">
                        <div className="d-flex flex-wrap flex-stack">
                          <div className="d-flex flex-column flex-grow-1">
                            <div className="d-flex flex-wrap">
                              <div className=" min-w-100px px-4 me-4">
                                <div className="d-flex align-items-center">
                                  <div
                                    className="fs-5 fw-bold"
                                  >
                                    {startDate ? moment(startDate, 'YYYY-MM-DD').format('DD MMM YY') : '---'}
                                  </div>
                                </div>
                                <div className="fw-semibold fs-6 text-gray-400">
                                  Start date
                                </div>
                              </div>
                              <div className=" min-w-100px px-4 me-4">
                                <div className="d-flex align-items-center">
                                  <div
                                    className="fs-5 fw-bold"
                                  >
                                    {endDate ? moment(endDate, 'YYYY-MM-DD').format('DD MMM YY') : '---'}
                                  </div>
                                </div>
                                <div className="fw-semibold fs-6 text-gray-400">
                                  End date
                                </div>
                              </div>
                              <div className="me-4 px-4">
                                <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">
                                  Owner
                                </span>
                                <div
                                  className="symbol symbol-25px symbol-circle"
                                  data-bs-toggle="tooltip"
                                  title={`${owner.fullName}`}
                                >
                                  <div className="symbol-label solid">
                                    <span className="fs-7 text-info fw-normal">{owner.initials}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-end pe-0 px-4">
                                <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">Users</span>
                                <div className="symbol-group symbol-hover flex-nowrap">
                                  {users.length > 3 ? (
                                    <>
                                      {users.slice(0, 2).map((user, index) => {
                                        const backgroundColors = ['bg-warning', 'bg-primary'];
                                        const textColors = ['text-inverse-warning', 'text-inverse-primary'];
                                        const bgColor = backgroundColors[index % 2];
                                        const textColor = textColors[index % 2];
                                        return (
                                          <div
                                            key={user.id}
                                            className="symbol symbol-25px symbol-circle"
                                            data-bs-toggle="tooltip"
                                            title={user.fullName}
                                          >
                                            <span className={`symbol-label ${index % 2 === 0 ? 'solid' : 'outline'} fs-7 fw-normal ${bgColor} ${textColor}`}>
                                              {user.initials}
                                            </span>
                                          </div>
                                        );
                                      })}
                                      <span
                                        className="symbol symbol-25px symbol-circle"
                                        data-bs-toggle="tooltip"
                                        title={`+${users.length - 2} more`}
                                        onClick={handleUserShow}
                                        onKeyDown={handleKeyPress}
                                      >
                                        <span className="symbol-label solid fs-7 fw-normal">+{users.length - 2}</span>
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {users.map((user, index) => {
                                        const backgroundColors = ['bg-warning', 'bg-danger'];
                                        const textColors = ['text-inverse-warning', 'text-inverse-danger'];
                                        const bgColor = backgroundColors[index % 2];
                                        const textColor = textColors[index % 2];
                                        return (
                                          <div
                                            key={user.id}
                                            className="symbol symbol-25px symbol-circle"
                                            data-bs-toggle="tooltip"
                                            title={user.fullName}
                                          >
                                            <span className={`symbol-label ${index % 2 === 0 ? 'solid' : 'outline'} fs-7 fw-normal ${bgColor} ${textColor}`}>
                                              {user.initials}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </>
                                  )}
                                  {users.length === 0 && (
                                    <div className="symbol symbol-circle symbol-25px">
                                      <div className="symbol-label solid bg-light-secondary">
                                        <span className="fs-7">N/A</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <UserModal showModal={showUserModal} handleUserClose={handleUserClose} users={users} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5">
                <div className="card card-flush h-100">
                  <div className="card-body pt-6 pb-6 align-items-center">
                    <div className="row">
                      <div className="col-md-4 col-12 h-100">
                        <div className="card-title d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                              {progressPercentage}%
                            </span>
                          </div>
                          <span className="text-gray-400 pt-1 pb-1 fw-semibold fs-6">
                            Project progress
                          </span>
                        </div>
                      </div>
                      <div className="col-md-8 col-12 d-flex">
                        <div className="flex-center me-5 pt-2 w-50">
                          {Object.values(taskCount)?.reduce((a, b) => (a + b), 0) > 0 ? renderDonutChart(taskCount)
                            : (
                              <Chart
                                options={{
                                  labels: [' '],
                                  legend: { show: false },
                                  dataLabels: { enabled: false },
                                  stroke: {
                                    width: 2,
                                  },
                                  plotOptions: {
                                    pie: {
                                      donut: {
                                        labels: {
                                          show: false,
                                        },
                                      },
                                    },
                                  },
                                  colors: ['#d3d3d3'],
                                  tooltip: { enabled: false },
                                  chart: {
                                    events: {
                                      click: (event) => {
                                        event.stopPropagation();
                                      },
                                    },
                                  },
                                }}
                                series={[1]}
                                type="donut"
                                width="150px"
                                style={{ position: 'relative', left: '-40px', top: '-10px' }}
                              />
                            )}
                        </div>
                        <div className="flex-column content-justify-center w-100">
                          <div className="d-flex fs-6 fw-semibold align-items-center">
                            <div className="bullet w-8px h-8px rounded-2" style={{ backgroundColor: '#FF0000', marginRight: '10px' }} />
                            <div className="text-gray-500 flex-grow-1 me-4">To do</div>
                            <div className="fw-bolder text-gray-700 text-xxl-end">
                              {taskCount['TO DO']}
                            </div>
                          </div>
                          <div className="d-flex fs-6 fw-semibold align-items-center my-3">
                            <div className="bullet w-8px h-8px rounded-2 me-3" style={{ backgroundColor: 'rgb(255, 153, 0)' }} />
                            <div className="text-gray-500 flex-grow-1 me-4">
                              In progress
                            </div>
                            <div className="fw-bolder text-gray-700 text-xxl-end">
                              {taskCount['IN PROGRESS']}
                            </div>
                          </div>
                          <div className="d-flex fs-6 fw-semibold align-items-center">
                            <div className="bullet w-8px h-8px rounded-2 me-3" style={{ backgroundColor: '#47BE7D' }} />
                            <div className="text-gray-500 flex-grow-1 me-4">Completed</div>
                            <div className="fw-bolder text-gray-700 text-xxl-end">
                              {taskCount.COMPLETED}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-12 d-flex justify-content-between mt-5">
                        <p className="mb-0 fw-bold fs-5">
                          Total Tasks : <span className="text-primary">{totalTask}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2">
                <div className="card-flush h-100">
                  <div className="card-body">
                    <div className="row p-0 g-2">
                      <div className="col-12">
                        <div className="border border-warning border-dashed border-gray-300 text-center rounded py-2 bg-light-warning">
                          {(daysToGo < 0) ? (
                            <span
                              className="fs-2hx fw-bold text-gray-900"
                            >
                              NA
                            </span>
                          )
                            : <CountUp className="fs-2hx fw-bold text-gray-900" end={daysToGo} />}
                          <span className="fs-4 fw-semibold text-warning d-block">
                            Days to Go
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="border border-primary border-dashed border-gray-300 text-center rounded py-2 bg-light-primary">
                          <span
                            className="fs-2hx fw-bold text-gray-900"
                          >
                            {tasksOverdue}
                          </span>
                          <span className="fs-4 fw-semibold text-primary d-block">
                            Tasks overdue
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 pt-0">
          <div className="col-md-12">
            <div className="card priority-card">
              <div className="card-header pt-7 border-bottom d-block">
                <h1 className="mb-5 fs-3">Recent Tasks</h1>
                <div className="modify-button d-flex">
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <button onClick={handleShow} className="btn btn-sm btn-outline-primary">
                      <i className="fa fa-download fa-plus me-1" />Task
                    </button>
                    {show && (
                      <AddTask show={show} onHide={closeAddTaskModal} projectName={projectData.data.projectStats.name} projectId={projectData?.data?.projectStats?.id} usersData={projectData.data.projectStats.users} />
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body p-4 py-2 table-responsive">
                <TaskTable project={projectName} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {decryptedRole?.id !== userRoleConstants.USER
        && <UpdateProjectModal show={showUpdateModal} onHide={handleCloseUpdateModal} projectId={projectName} />}
      <ProjectHistoryModal isOpen={isModalOpen} onClose={closeModal} projectId={projectName} />
    </>
  );
}
export default ProjectDetails;
