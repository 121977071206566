import { Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { setAuth } from '../../../Redux/slices/auth';
import { userRoleConstants } from '../../../utils/constant';
import { decryptRole } from '../../../utils/cryptoUtils';

const workeazyAdminId = process.env.REACT_APP_WORKEAZY_ADMIN_ID;
const workeazyAdminUnauthorizedPaths = ['/', '/users', '/kanban', '/projects', '/tasks', '/projectDetails', '/reports', '/timesheet'];
function PR({ children }) {
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth);

  const roleMemo = useMemo(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    return roleDecrypted;
  }, []);

  if (authStatus.token && authStatus.isAuthenticated) {
    if (!user?.userId) {
      return null;
    }

    if (user?.userId === parseInt(workeazyAdminId, 10)
      && workeazyAdminUnauthorizedPaths.includes(pathname)) {
      return <Navigate to="/companies" replace />;
    }

    if (user?.userId !== parseInt(workeazyAdminId, 10) && pathname === '/companies') {
      return <Navigate to="/" replace />;
    }

    if ((!user?.client.features?.Reports && pathname === '/reports') || (!user?.client.features?.Timesheet && pathname === '/timesheet')) {
      return <Navigate to="/" replace />;
    }

    if ((roleMemo.id === userRoleConstants.USER && pathname === '/users') || (roleMemo.id === userRoleConstants.USER && pathname === '/reports')) {
      return <Navigate to="/" replace />;
    }

    return children;
  }
  const localToken = localStorage.getItem('token');
  if (localToken) {
    dispatch(setAuth({ ...authStatus, isAuthenticated: true, token: localToken }));
    return children;
  }
  if (!authStatus.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
}

export default PR;
