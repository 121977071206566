import { useMemo, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Table from './Table';
import TableHeader from './TableHeader';
import { decryptRole } from '../../utils/cryptoUtils';
import { useGetMasterDataQuery } from '../../Redux/services/masterData';
import LogHoursModal from '../Tasks/TaskDetails/LogHoursModal';
import { useExportTimesheetMutation } from '../../Redux/services/timesheet';
import { userRoleConstants } from '../../utils/constant';

function Timesheet() {
  const { data: masterData } = useGetMasterDataQuery();
  const [filtersData, setFiltersData] = useState({ month: moment().format('M') });
  const [showLogHoursModal, setShowLogHoursModal] = useState(false);
  const [exportTimesheet, { isLoading }] = useExportTimesheetMutation();

  const roleMemo = useMemo(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    return roleDecrypted;
  }, []);

  const handleExportTimesheet = async () => {
    try {
      const response = await exportTimesheet({ data: filtersData });

      const blob = new Blob([response?.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8',
      });

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;

      a.download = `Timesheet-${new Date().toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}.xlsx`;
      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      window.toastr.error(error?.error?.message ?? 'Something went wrong!');
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between progress-button mt-5">
        <div>
          <h2 className="pb-0 mb-0 ms-0 pt-0">Timesheet</h2>
          <ul className="breadcrumb fw-semibold fs-base mb-1">
            <li className="breadcrumb-item text-muted">
              <Link to="/" className="text-muted text-hover-primary">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-900">
              Timesheet
            </li>
          </ul>
        </div>
        <div className="header-buttons">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button onClick={() => setShowLogHoursModal({})} type="button" className="btn btn-sm btn-primary"><i className="fa fa-download fa-plus me-1" />Log Time</button>
            {(roleMemo?.id !== userRoleConstants.USER)
              && (<Link onClick={handleExportTimesheet} download className="btn btn-sm btn-outline-primary"><i className="fa fa-download me-1" />Export { isLoading && <span className="spinner-border spinner-border-sm" />}</Link>)}
          </div>
        </div>
      </div>

      <div className="row mt-5 pt-0">
        <div className="col-md-12">
          <div className="card priority-card">
            <TableHeader
              masterData={masterData}
              roleMemo={roleMemo}
              setFiltersData={setFiltersData}
            />
            <Table
              filtersData={filtersData}
              masterData={masterData}
            />
          </div>
        </div>
      </div>
      {showLogHoursModal && (
        <LogHoursModal
          showModalBox={showLogHoursModal}
          setShowModalBox={setShowLogHoursModal}
          modalValues={{ modalType: 'addOnTimesheet', date: moment().format('YYYY-MM-DD') }}
          type="addOnTimesheet"
          masterData={masterData}
        />
      )}
    </>
  );
}

export default Timesheet;
