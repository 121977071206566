import React from 'react';
import { Modal } from 'react-bootstrap';

function UserModal({ showModal, handleUserClose, users }) {
  return (
    <Modal show={showModal} onHide={handleUserClose} centered size="sm">
      <Modal.Header closeButton>
        <Modal.Title>All Members</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4 py-2 table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <table className="table data-table align-middle table-row-dashed fs-6 gy-5">
            <tbody className="fw-semibold text-gray-600">
              {users.map((user) => (
                <tr key={user.id}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="symbol-group symbol-hover mb-1">
                        <div className="symbol symbol-circle symbol-25px">
                          <div className="symbol-label solid bg-light-info">
                            <span className="fs-7 text-info">{user.initials}</span>
                          </div>
                        </div>
                      </div>
                      <span className="text-gray-800 fs-5 fw-bold ms-2">
                        {user.firstName} {user.lastName}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UserModal;
