import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';
import { masterDataService } from './masterData';

const apiHost = process.env.REACT_APP_API_HOST;

export const userService = createApi({
  reducerPath: 'userService',
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        return headers;
      }
      return {};
    },
  }),
  tagTypes: ['Users'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getLoggedUser: builder.query({
      query: () => ({
        url: '/users/me',
        method: 'get',
      }),
      keepUnusedDataFor: 0,
    }),
    getUsersListWithFilters: builder.query({
      query: ({
        filters, page, size = 5, sortColumn = 'name', sortOrder = 'asc',
      }) => ({
        data: filters,
        url: `/users/filter?page=${page}&size=${size}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
        method: 'post',
      }),
      providesTags: ['Users'],
    }),
    addUser: builder.mutation({
      query: (data) => ({
        data,
        url: '/users',
        method: 'post',
      }),
      invalidatesTags: ['Users'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            masterDataService.util.invalidateTags(['MasterData']),
          );
        } catch (err) {
          // eslint-disable-next-line
          console.log(err);
        }
      },

    }),
    editUser: builder.mutation({
      query: ({ data, id }) => ({
        data,
        url: `/users/${id}`,
        method: 'put',
      }),
      invalidatesTags: ['Users'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            masterDataService.util.invalidateTags(['MasterData']),
          );
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
  }),

});

export const {
  useGetLoggedUserQuery, useGetUsersListWithFiltersQuery,
  useAddUserMutation, useEditUserMutation,
} = userService;
