import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';

const apiHost = process.env.REACT_APP_API_HOST;

export const globalSearchService = createApi({
  reducerPath: 'globalSearchService',
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        return headers;
      }
      return {};
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    globalSearch: builder.query({
      query: ({ data }) => ({
        data,
        url: '/global-search',
        method: 'post',
      }),
    }),
  }),
});

export const {
  useGlobalSearchQuery,
} = globalSearchService;
