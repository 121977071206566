import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MilestoneDetailsForm from './MilestoneDetailsForm';
import { userRoleConstants } from '../../../utils/constant';

function MilestoneCard({
  milestoneData, handleDelete, modalState, milestoneList, setMilestoneList,
  openMilestoneList, openMilestoneDetailsForm,
}) {
  const { id: roleId } = useSelector((state) => state.user.role);

  return (
    <>
      <div
        className="rounded py-2 p-3 m-2"
        style={{
          width: '160px', height: '300px', backgroundColor: milestoneData?.backgroundHexCode, border: `1px solid ${milestoneData?.textHexCode}`,
        }}
      >
        {roleId === userRoleConstants.SUPER_ADMIN ? (
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-start col-3 py-1">
              {milestoneData?.editOnly ? null : (
                <span
                  className="btn-color-gray-400 btn-active-color-primary cursor-move"
                  title="Movable"
                  aria-label="Movable"
                >
                  <i className="fa fa-arrows fs-5 me-4" />
                </span>
              )}
            </div>
            <div className="d-flex card-toolbar justify-content-end col-3 py-1">
              <span
                className="btn-color-gray-400 btn-active-color-primary cursor-pointer"
                title="Edit"
                role="button"
                tabIndex="0"
                onClick={() => openMilestoneDetailsForm({ ...milestoneData, modalMode: 'edit' })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    openMilestoneDetailsForm({ ...milestoneData, modalMode: 'edit' });
                  }
                }}
                aria-label="Edit"
              >
                <i className="fa fa-edit ms-4" />
              </span>
              {!milestoneData?.editOnly ? (
                <span
                  className="btn-color-gray-400 btn-active-color-primary cursor-pointer"
                  title="Delete"
                  role="button"
                  tabIndex="0"
                  onClick={() => handleDelete(milestoneData?.id)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleDelete();
                    }
                  }}
                  aria-label="Delete"
                >
                  <i className="fa fa-trash ms-4" />
                </span>
              ) : null}
            </div>
          </div>
        ) : (<div className="p-2" />)}

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 300 }}
          overlay={<Tooltip>{milestoneData?.name}</Tooltip>}
        >
          <span className="fs-4 fw-semibold d-block pb-2 text-truncate user-select-none" style={{ color: milestoneData?.textHexCode }}>{milestoneData?.name}</span>
        </OverlayTrigger>
        <div
          className="task-card"
          style={{
            userSelect: 'none',
            padding: '15px',
            margin: '0 0 20px 0',
            backgroundColor: '#fff',
            color: '#333',
            borderRadius: '1.15rem',
            boxShadow: '0px 0px 13px 0px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div className="task-id">#Task</div>
          <div className="d-flex flex-column align-items-start border-bottom pb-3" style={{ overflowWrap: 'anywhere' }}>
            <span className="text-gray-900-50 pt-2 mb-1">Task Title</span>
          </div>
        </div>
      </div>
      <MilestoneDetailsForm
        milestoneList={milestoneList}
        setMilestoneList={setMilestoneList}
        show={modalState.activeModal === 'milestoneDetailsForm'}
        onHide={openMilestoneList}
        modalState={modalState}
      />
    </>
  );
}

export default MilestoneCard;
