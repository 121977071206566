import React from 'react';
import { Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';
import { useGetTasksListWithFiltersQuery } from '../../Redux/services/task';

function TaskTable({ project }) {
  const { data: responseTasks, error, isLoading } = useGetTasksListWithFiltersQuery({
    filters: { project: [parseInt(project, 10)] },
    page: 1,
  });

  if (isLoading) return <div>Loading tasks...</div>;
  if (error) return <div>Error: {error.message}</div>;

  // Extract tasks from the response data
  const tasks = responseTasks?.data?.tasks;

  const statusColorMap = {
    'To do': 'badge-light-info',
    'In Progress': 'badge-light-warning',
    Complete: 'badge-light-success',
  };

  return (
    <div>
      <table className="table data-table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_sales_table">
        <thead>
          <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
            <th style={{ width: '100px' }}>Task ID</th>
            <th style={{ width: '175px' }}>Title</th>
            <th style={{ width: '175px' }}>Project</th>
            <th style={{ width: '100px' }}>Start Date</th>
            <th style={{ width: '100px' }}>End Date</th>
            <th style={{ width: '100px' }}>Users</th>
            <th style={{ width: '100px' }}>Status</th>
            <th style={{ width: '100px' }}>Actions</th>
          </tr>
        </thead>
        <tbody className="fw-semibold text-gray-600">
          {tasks && tasks.map((task) => (
            <tr key={task.id}>
              <td>
                <Link to={`/tasks/${task.uniqueTaskId}`} className="text-gray-800 text-hover-primary fw-bold">
                  {task.uniqueTaskId}
                </Link>
              </td>
              <td>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 300 }}
                  overlay={<Tooltip id="button-tooltip">{task.title}</Tooltip>}
                >
                  <div style={{ maxWidth: '200px' }} className="text-truncate">
                    <span className="text-gray-800 fs-5 fw-bold">
                      {task.title}
                    </span>
                  </div>
                </OverlayTrigger>
              </td>
              <td>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 300 }}
                  overlay={<Tooltip id="button-tooltip">{task.Project.name}</Tooltip>}
                >
                  <div style={{ maxWidth: '200px' }} className="text-truncate">
                    {task.Project.name}
                  </div>
                </OverlayTrigger>

              </td>
              <td>{moment(task.startDate).format('DD MMM YY')}</td>
              <td>{moment(task.endDate).format('DD MMM YY')}</td>
              <td>
                <div className="symbol-group symbol-hover mb-1">
                  {task?.taskUsers?.length > 3 ? (
                    <>
                      {task?.taskUsers?.slice(0, 2).map((user, index) => {
                        const backgroundColors = ['bg-warning', 'bg-primary'];
                        const textColors = ['text-inverse-warning', 'text-inverse-primary'];
                        const bgColor = backgroundColors[index % 2];
                        const textColor = textColors[index % 2];
                        return (
                          <div
                            key={user.id}
                            className="symbol symbol-25px symbol-circle"
                            data-bs-toggle="tooltip"
                            title={user.fullName}
                          >
                            <span className={`symbol-label ${index % 2 === 0 ? 'solid' : 'outline'} fs-7 fw-normal ${bgColor} ${textColor}`}>
                              {user.initials}
                            </span>
                          </div>
                        );
                      })}
                      <span
                        className="symbol symbol-25px symbol-circle"
                        data-bs-toggle="tooltip"
                        title={`+${task.taskUsers.length - 2} more`}
                      >
                        <span className="symbol-label solid fs-7 fw-normal">+{task.taskUsers.length - 2}</span>
                      </span>
                    </>
                  ) : task?.taskUsers?.map((user, index) => {
                    const backgroundColors = ['bg-warning', 'bg-danger'];
                    const textColors = ['text-inverse-warning', 'text-inverse-danger'];
                    const bgColor = backgroundColors[index % 2];
                    const textColor = textColors[index % 2];
                    return (
                      <div
                        key={user.id}
                        className="symbol symbol-25px symbol-circle"
                        data-bs-toggle="tooltip"
                        title={user.fullName}
                      >
                        <span className={`symbol-label ${index % 2 === 0 ? 'solid' : 'outline'} fs-7 fw-normal ${bgColor} ${textColor}`}>
                          {user.initials}
                        </span>
                      </div>
                    );
                  })}
                </div>
                {task?.taskUsers?.length === 0 && <div>---</div>}
              </td>
              <td>
                {task?.TaskStatus?.name && (
                  <div className={`badge ${statusColorMap[task.TaskStatus.name]} fw-bold`}>
                    {task.TaskStatus.name === 'Complete' ? 'Completed' : task.TaskStatus.name}
                  </div>
                )}
              </td>
              <td>
                <Link to={`/tasks/${task.uniqueTaskId}`} aria-label="View Details">
                  <i className="fa fa-eye me-3" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TaskTable;
