/* eslint-disable react/no-array-index-key */

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useGetMonthlyTimesheetQuery } from '../../Redux/services/timesheet';
import LogDetails from './LogDetails';
import './Table.css';

function Table({ filtersData, masterData }) {
  const { data: timesheetMonthlyData } = useGetMonthlyTimesheetQuery(filtersData);
  const [showlogDetailsModal, setShowlogDetailsModal] = useState(false);
  const [dataForLogDetails, setDataForlogDetails] = useState({});
  const [totalHoursPerDay, setTotalHoursPerDay] = useState({});
  const [totalHours, setTotalHours] = useState(0);

  const handleViewLogDetails = ({ project, user, date }) => {
    setDataForlogDetails({ project, user, date });
    setShowlogDetailsModal(true);

  };
  const onHideLogDetails = () => {
    setShowlogDetailsModal(false);
  };

  const calculateTotalHours = () => {
    const totals = {};
    timesheetMonthlyData?.data?.records?.forEach((record) => {
      totals.totalHours = (totals.totalHours || 0) + (record.totalHours || 0);
    });
    setTotalHours(totals?.totalHours);
  };

  const calculateTotalHoursPerDay = () => {
    const totals = {};
    timesheetMonthlyData?.data?.records?.forEach((record) => {
      record?.data?.forEach((item, j) => {
        if (j >= 3) {
          totals[j] = (totals[j] || 0) + (item.rowData || 0);
        }
      });
    });
    const hours = Object.fromEntries(Object.entries(totals)
      .map(([key, value]) => [key, parseFloat(value.toFixed(2))]));
    setTotalHoursPerDay(hours);
  };
  useEffect(() => {
    calculateTotalHoursPerDay();
    calculateTotalHours();

    // eslint-disable-next-line
  }, [timesheetMonthlyData?.data?.records]);

  return (
    <div className="pt-3">
      <div className="card-body pt-0 pb-4">
        <div className="row">
          <div className="col-4 table-container-1" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
            <table className="table to-do-table table-row-dashed align-middle gy-5 gs-7" id="kt_table_users">
              <thead>
                <tr className="text-start fw-bold fs-7 text-uppercase gs-0">
                  {timesheetMonthlyData?.data?.headers?.map((item, i) => ((item?.heading
                      && i < 3) ? (
                        <th key={item?.heading} className="">
                          <Link to="#" className="sortable">{item?.heading} <br />
                            {item?.heading === 'Total' && <span className="text-muted">Hours</span>}
                          </Link>
                        </th>
                    ) : null))}
                </tr>
              </thead>
              <tbody>
                {timesheetMonthlyData?.data?.records?.map((record, i) => (
                  <tr key={i} className="text-start fw-bold fs-7 text-uppercase gs-0">
                    {record?.data.map((item, j) => {
                      if (item?.projectId || item?.userId || item?.totalHours) {
                        if (item?.projectId) {
                          return (
                            <td key={j} className="text-start text-gray-500 fw-bold fs-7 gs-0">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 300 }}
                                overlay={<Tooltip id="button-tooltip">{item?.rowData}</Tooltip>}
                              >
                                <span style={{ maxWidth: '200px', overflow: 'hidden', display: 'inline-block' }} className="text-truncate">
                                  {item?.rowData}
                                </span>
                              </OverlayTrigger>
                            </td>
                          );
                        }
                        return <td key={j} className="text-start text-gray-500 fw-bold fs-7 gs-0">{item?.rowData}</td>;

                      }
                      return null;
                    })}
                  </tr>
                ))}
                {timesheetMonthlyData?.data?.records?.length > 0 && (
                <tr className="text-start fw-bold fs-7 text-uppercase gs-0">
                  <td aria-label="empty-cell" />
                  <td className="text-start text-gray-500 fw-bold fs-7 gs-0">
                    <Link className="text-uppercase">
                      Total
                    </Link>
                  </td>
                  <td className="text-start text-gray-500 fw-bold fs-7 gs-0">
                    <Link>
                      {totalHours}
                    </Link>
                  </td>
                </tr>
                )}

              </tbody>
            </table>
          </div>
          <div className="col-8" style={{ paddingLeft: '0rem' }}>
            <div className="table-responsive">

              <table className="table to-do-table align-middle table-row-dashed fs-7 gy-5 gs-2" id="kt_table_users">
                <thead>
                  <tr className="fw-bold fs-7 text-uppercase gs-0 text-center">
                    { timesheetMonthlyData?.data?.headers
                      ?.map((item) => (item?.heading ? null : (
                        <th style={{ background: 'var(--bs-body-bg)' }} key={item?.date} className="min-w-70px mw-70px">
                          <Link to="#" className="sortable">{item?.date}
                            <br />
                            {item?.day}
                          </Link>
                        </th>
                      )))}
                  </tr>
                </thead>
                <tbody>
                  {timesheetMonthlyData?.data?.records?.map((record, i) => (
                    <tr key={i} className="fw-bold fs-7 text-uppercase gs-0 text-center">
                      {record?.data
                        ?.map((item, j) => ((j > 2)
                          && (
                            // eslint-disable-next-line
                            <td key={j} style={{ background: 'var(--bs-body-bg)' }} className="cursor-pointer min-w-70px mw-70px py-4" onClick={() => handleViewLogDetails({ project: record?.projectId, user: record?.userId, date: item?.date })}>{item?.rowData}
                            </td>
                          )))}
                    </tr>
                  ))}
                  {timesheetMonthlyData?.data?.records?.length !== 0 && (
                  <tr className="fw-bold fs-7 text-uppercase gs-0 text-center">
                    {Object.keys(totalHoursPerDay)
                      ?.map((key, index) => ((
                        <td style={{ background: 'var(--bs-body-bg)' }} key={index} className="min-w-70px mw-70px text-start py-4 text-gray-500 fw-bold fs-7 gs-0 text-center">
                          <Link>
                            {totalHoursPerDay[key]}
                          </Link>
                        </td>
                      )
                      ))}
                  </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showlogDetailsModal && (
        <LogDetails
          showlogDetailsModal={showlogDetailsModal}
          setShowLogDetails={setShowlogDetailsModal}
          onHide={onHideLogDetails}
          dataForLogDetails={dataForLogDetails}
          masterData={masterData}
        />
      )}
    </div>
  );
}

export default Table;
