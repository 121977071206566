import { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';

export default function StrictModeDroppable({ children, droppableId, direction }) {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => cancelAnimationFrame(animation);
  }, []);

  if (!enabled) return null;

  return (
    <Droppable droppableId={droppableId} direction={direction}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={{
            height: '60vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          {...provided.droppableProps}
        >
          {children(provided, snapshot)}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
