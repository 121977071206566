import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Home from './components/Home';
import Login from './components/Auth/Login';
import ForgotPassword from './components/Auth/ForgotPassword';
import PR from './components/Auth/Middleware/ProtectedRoutes';
import KanbanBoard from './components/Kanban';
import UsersList from './components/Users/List';
import ViewProjectList from './components/Project/ViewProjectList';
import ProjectDetails from './components/Project/ProjectDetails';
import TasksList from './components/Tasks/List';
import TaskDetails from './components/Tasks/TaskDetails';
import Report from './components/Reports';
import Timesheet from './components/Timesheet';
import Companies from './components/Companies';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<Layout />}>
          {/* PR - Protected Routes */}
          <Route exact path="/" element={<PR><Home /></PR>} />
          <Route exact path="/users" element={<PR><UsersList /></PR>} />
          <Route exact path="/kanban" element={<PR><KanbanBoard /></PR>} />
          <Route exact path='/projects' element={<PR><ViewProjectList /></PR>} />
          <Route exact path='/projectDetails/:projectName' element={<PR><ProjectDetails /></PR>} />
          <Route exact path='/projects' element={<PR><ViewProjectList /></PR>} />
          <Route exact path='/tasks' element={<PR><TasksList /></PR>} />
          <Route exact path='/tasks/:uniqueId' element={<PR><TaskDetails /></PR>} />
          <Route exact path="/reports" element={<PR><Report /></PR>} />
          <Route exact path="/timesheet" element={<PR><Timesheet /></PR>} />
          <Route exact path="/companies" element={<PR><Companies /></PR>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
