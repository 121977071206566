import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { regexPatterns } from '../../utils/constant';
import { useAddClientMutation, useUpdateClientMutation } from '../../Redux/services/company';
import { encryptPassword } from '../../utils/cryptoUtils';

const renderStatus = () => [
  { value: 1, label: 'Active' },
  { value: 2, label: 'Inactive' },
];

const customSelectStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '500',
    opacity: '.7',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '1.15rem',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    borderRadius: '1.15rem',
    backgroundColor: 'var(--bs-dropdown-bg)',
    fontSize: '13px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'var(--bs-dropdown-bg)',
    color: state.isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
    ':hover': {
      backgroundColor: 'var(--bs-component-hover-bg)',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--bs-gray-300)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
    fontSize: '1.1rem',
  }),
};

function AddCompanyModal({ onHide, type = 'add', clientData = {} }) {
  const [addClient, { isLoading }] = useAddClientMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [updateClient, { isLoading: isUpdating }] = useUpdateClientMutation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (type === 'edit') {
      reset({
        clientName: clientData?.name ?? '',
        adminName: clientData?.adminName ?? '',
        contact: clientData?.adminPhoneNo ?? '',
        status: clientData?.ClientStatusId ? {
          label: clientData?.ClientStatusId === 1 ? 'Active' : 'Inactive',
          value: clientData?.ClientStatusId,
        } ?? '' : '',
        location: clientData?.location ?? '',
        password: clientData?.adminPassword ?? '',
        email: clientData?.adminEmail ?? '',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    try {
      const encryptedPassword = encryptPassword(data.password);
      const reqBody = {
        ...data,
        password: encryptedPassword,
        status: data.status.value,
      };
      if (type === 'edit') {
        const response = await updateClient({
          id: clientData?.id,
          data: reqBody,
        });
        if (response?.data?.success) {
          window.toastr.success(response?.data?.message);
          onHide();
        } else {
          window.toastr.error(response?.error?.data?.message ?? 'Something went wrong.');
        }
      } else {
        const response = await addClient(reqBody);
        if (response?.data?.success) {
          window.toastr.success(response?.data?.message);
          onHide();
        } else {
          window.toastr.error(response?.error?.data?.message ?? 'Something went wrong.');
        }
      }
    } catch (error) {
      window.toastr.error(error?.data?.message ?? 'Something went wrong');
    }
  };

  const handleCancel = () => {
    reset({
      clientName: '',
      adminEmail: '',
      adminContact: '',
      status: '',
      location: '',
      password: '',
      email: '',
    });
    onHide();
  };

  return (
    <Modal show centered onHide={onHide}>
      <Modal.Header className="pb-2" closeButton>
        <Modal.Title>
          <h2>{type === 'edit' ? 'Update' : 'Add'} Company</h2>
        </Modal.Title>
      </Modal.Header>
      <div className="modal-body  mb-4">

        <form className="form" onSubmit={handleSubmit(onSubmit)}>

          <div className="row mt-0">
            <div className="col-md-12">
              <h5 className="mb-6">Information</h5>
            </div>

            <div className="col-md-6 fv-row">

              <label htmlFor="clientName" className="required fs-6 fw-semibold form-label mb-2 assign-to">Name</label>
              <input
                {...register('clientName', {
                  required: { value: true, message: 'Required' },
                  validate: (value) => {
                    if (value.trim() === '') {
                      return 'Required';
                    }
                    if (value.trim().length < 3) {
                      return "Name can't be less than 3 characters";
                    }
                    if (value.trim().length > 100) {
                      return "Name can't be more then 100 characters";
                    }
                    return true;
                  },
                })}
                id="clientName"
                type="text"
                className="form-control"
              />
              {errors?.clientName
                 && <div className="text-danger small"> {errors.clientName?.message} </div>}
            </div>

            <div className="col-md-6 fv-row">

              <label htmlFor="location" className="fs-6 fw-semibold form-label mb-2 assign-to">Location</label>
              <input
                {...register('location')}
                id="location"
                type="text"
                className="form-control"
              />
              {errors?.location
                 && <div className="text-danger small"> {errors.location?.message} </div>}
            </div>

            <div className="col-md-6 mt-5 fv-row ">

              <label htmlFor="status" className="required fs-6 fw-semibold form-label mb-2 assign-to">Status</label>

              <Controller
                name="status"
                rules={{
                  required: { value: true, message: 'Required' },
                }}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={renderStatus()}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={customSelectStyles}
                  />
                )}
              />
              {errors?.status
                 && <div className="text-danger small"> {errors.status?.message} </div>}
            </div>

          </div>

          <div className="row mt-8">
            <div className="col-md-12">
              <h5 className="mb-6">Admin</h5>
            </div>
            <div className="col-md-6 fv-row">
              <label htmlFor="name" className="required fs-6 fw-semibold form-label mb-2 assign-to">Name</label>
              <input
                {...register('adminName', {
                  required: { value: true, message: 'Required' },
                  validate: (value) => {
                    if (value.trim() === '') {
                      return 'Required';
                    }
                    if (value.trim().length < 3) {
                      return "Name can't be less than 3 characters";
                    }
                    if (value.trim().length > 100) {
                      return "Name can't be more then 100 characters";
                    }
                    return true;
                  },
                })}
                id="name"
                type="text"
                className="form-control"
              />
              {errors?.adminName
                 && <div className="text-danger small"> {errors.adminName?.message} </div>}
            </div>

            <div className="col-md-6 fv-row">
              <label htmlFor="email" className="required fs-6 fw-semibold form-label mb-2 assign-to">Email</label>
              <input {...register('email', { required: { value: true, message: 'Email is required' }, pattern: { value: regexPatterns.USER_EMAIL, message: 'Please insert a valid email address' } })} id="email" type="text" className="form-control" />
              {errors?.email
                 && <div className="text-danger small"> {errors.email?.message} </div>}
            </div>

            <div className="col-md-6 fv-row mt-5 identity">
              <label htmlFor="password" className="required fs-6 fw-semibold form-label mb-2 assign-to">Password</label>
              <input
                {...register('password', {
                  required: { value: true, message: 'Password is required' },
                  maxLength: { value: 20, message: 'Maximum length should be 20 characters' },
                  minLength: { value: 6, message: 'Minimum length should be 6 characters' },
                  pattern: { value: regexPatterns.USER_PASSWORD, message: 'Password must atleast have 1 alphabet and 1 number' },
                })}
                type={showPassword ? 'text' : 'password'}
                className="form-control rounded"
                autoComplete="off"
              />
              <span
                onClick={() => setShowPassword((prev) => !prev)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setShowPassword((prev) => !prev);
                  }
                }}
                role="button"
                tabIndex={0}
                aria-label={showPassword ? 'Hide password' : 'Show password'}
                className={`fa fa-fw ${!showPassword ? 'fa-eye' : 'fa-eye-slash'} field-icon toggle-password" `}
              />
              {errors?.password
                 && <div className="text-danger small position-absolute"> {errors.password?.message} </div>}
            </div>

            <div className="col-md-6 mt-5 fv-row ">
              <label htmlFor="contact" className="fs-6 fw-semibold form-label mb-2 assign-to">Contact</label>
              <input
                {...register('contact', {
                  pattern: { value: regexPatterns.USER_PHONENUMBER, message: 'Invalid Phone no' },
                })}
                maxLength={10}
                type="tel"
                className="form-control"
              />
              {errors?.contact
                 && <div className="text-danger small"> {errors.contact?.message} </div>}
            </div>

          </div>

          <div className="text-left pt-6 mt-5">

            <button
              type="submit"
              className="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
              disabled={isLoading || isUpdating}
            >
              <span className="indicator-label">Save</span>
              {(isLoading || isUpdating) && (
              <span className="indicator-progress">Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
              )}
            </button>
            <button
              type="button"
              className="btn btn-sm btn-light ms-3"
              onClick={handleCancel}
            >Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddCompanyModal;
