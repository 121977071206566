import { Modal } from 'react-bootstrap';
import { useGetClientHistoryQuery } from '../../Redux/services/company';

function ClientHistoryModal({ onHide, id }) {
  const { data: clientHistory, isLoading } = useGetClientHistoryQuery({ id });
  return (
    <Modal show centered onHide={onHide} size="lg">
      <div className="pb-6">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Company History</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          height: isLoading ? '50vh' : 'auto', maxHeight: '50vh', overflowY: 'auto', scrollbarWidth: 'thin',
        }}
        >
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center min-vh-50" style={{ height: '100%' }}>
              <span className="spinner-border spinner-border-lg align-middle ms-2" />
            </div>
          ) : (
            <div className="timeline">
              {clientHistory?.data?.map((item) => (
                <div key={item?.date}>
                  <div className="timeline-item">
                    <div className="timeline-line h-100" style={{ marginLeft: '.68rem' }} />
                    <div className="timeline-icon symbol symbol-circle symbol-20px">
                      <div className="symbol-label bg-light">
                        <i className="fa-solid fa-circle-dot fs-9" />
                      </div>
                    </div>
                    <div className="timeline-content">
                      <div className="fs-3 fw-semibold text-primary mb-0">{item?.date ?? ''}</div>
                      {item?.history?.map((data, index) => (
                        // eslint-disable-next-line
                        <div key={index}>
                          <div className="separator separator-dashed my-3" />
                          <div className="pe-3 mb-0">
                            <div className="overflow-auto pb-0">
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-35px symbol-circle user-image" data-kt-initialized="1">
                                  <span className="fw-normal symbol-label solid bg-light-success text-success">
                                    {data?.byUser?.initials}
                                  </span>
                                </div>
                                <div className="ms-4">
                                  <div className="me-2 fs-4">
                                    {data?.changed?.length > 0
                                      ? data?.changed.map((changedData, i) => (
                                        // eslint-disable-next-line
                                        <div key={i} className="fs-4" dangerouslySetInnerHTML={{ __html: changedData }} />
                                      ))
                                      : data?.history}
                                  </div>
                                  <div className="d-flex align-items-center mt-0 fs-6">
                                    <div className="text-muted me-2 fs-7">
                                      {data?.timestamp} by <span className="text-primary">{data?.byUser?.fullName}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default ClientHistoryModal;
