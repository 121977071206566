import {
  Card, Col,
} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Chart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { projectStatusConstants } from '../../utils/constant';

const labels = ['Completed', 'In Progress', 'To do'];
export default function ProjectCard({ projectsData }) {

  return (
    <>
      {projectsData.projects.map((project) => (
        <Col key={project.id} xl={4} className="mb-5">
          <Card className="mb-6 mb-xl-9 h-100">
            <Card.Body className="pt-9 pb-5">
              <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                <div className="flex-grow-1">
                  <div className="justify-content-between align-items-start flex-wrap mb-2">
                    <div className="flex-column">
                      <div className="d-flex justify-content-between">
                        <div className="align-items-start">
                          <div className="fs-2 fw-bold">
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 300 }}
                              overlay={<Tooltip id="button-tooltip">{project?.name}</Tooltip>}
                            >
                              <h1 style={{ width: '200px' }} className="fs-2 me-1 fw-bold text-truncate">
                                {project?.name}
                              </h1>
                            </OverlayTrigger>
                          </div>
                        </div>
                        <div className="align-items-end">
                          <Link to={`/projectDetails/${project.id}`} className="btn btn-light btn-sm">
                            Details <i className="fa-solid fa-chevron-right fs-8" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1">
                      <div className="d-flex flex-wrap">
                        <div className="min-w-100px pt-3 px-4 me-4">
                          <div className="d-flex align-items-center">
                            <div className="fs-5 fw-bold">{project.startDate ? project.startDate : '- -'}</div>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">Start date</div>
                        </div>
                        <div className="min-w-100px pt-3 px-4 me-4">
                          <div className="d-flex align-items-center">
                            <div className="fs-5 fw-bold">{project.endDate ? project.endDate : '- -'}</div>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">End date</div>
                        </div>
                        <div className="min-w-100px pt-3 px-4 me-4">
                          <div className="d-flex align-items-center">
                            <div className="fs-5 fw-bold">{project?.ProjectStatus?.id === projectStatusConstants.COMPLETED ? 'Completed' : project?.progress?.name ?? 'Inactive'}</div>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">Status</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-5">
                      <div className="pt-4" style={{ position: 'relative' }}>
                        {project.totalTasks > 0 ? (
                          <Chart
                            options={{
                              labels,
                              legend: { show: false },
                              dataLabels: { enabled: false },
                              stroke: {
                                width: 2,
                              },
                              plotOptions: {
                                pie: {
                                  donut: {
                                    labels: {
                                      show: true,
                                      name: { show: false },
                                      value: {
                                        show: false,
                                        color: 'var(--bs-text-dark)',
                                        fontFamily: 'var(--bs-font-sans-serif)',
                                        fontSize: '2rem',
                                        formatter() {
                                          return project.totalTasks ?? 'Default';
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                              colors: ['#50cd89', '#ffc700', '#f1416c'], // [completed, inprogress, todo];
                            }}
                            series={[
                              project.groupedTaskCount?.COMPLETED,
                              project.groupedTaskCount?.['IN PROGRESS'],
                              project.groupedTaskCount?.['TO DO'],
                            ]}
                            type="donut"
                            width="210px"
                            style={{ position: 'relative', left: '-40px', top: '-10px' }}
                          />
                        ) : (
                          <Chart
                            options={{
                              labels: [' '],
                              legend: { show: false },
                              dataLabels: { enabled: false },
                              stroke: {
                                width: 2,
                              },
                              plotOptions: {
                                pie: {
                                  donut: {
                                    labels: {
                                      show: false,
                                    },
                                  },
                                },
                              },
                              colors: ['#d3d3d3'],
                              states: {
                                normal: {
                                  filter: {
                                    type: 'none',
                                  },
                                },
                                hover: {
                                  filter: {
                                    type: 'none',
                                  },
                                },
                                active: {
                                  filter: {
                                    type: 'none',
                                  },
                                },
                              },
                              tooltip: { enabled: false },
                              chart: {
                                events: {
                                  click: (event) => {
                                    event.stopPropagation();
                                  },
                                },
                              },
                            }}
                            series={[1]}
                            type="donut"
                            width="210px"
                            style={{ position: 'relative', left: '-40px', top: '-10px' }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="h-md-100 ps-4">
                        <div className="border-0 pt-3">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Recent Tasks</span>
                          </h3>
                        </div>
                        <div style={{
                          padding: '10px 0 10px 10px',
                          height: '150px',
                          overflowY: 'auto',
                          overflowX: 'hidden',
                        }}
                        >
                          {project.recentTasks.length > 0 ? (
                            project.recentTasks.map((task) => (
                              <div key={task.id} className="py-1 d-flex flex-column">
                                <div className="d-flex align-items-center position-relative mb-2">
                                  <div className="bullet w-5px h-5px rounded-2 bg-primary me-1" />
                                  <div className="fw-semibold ms-2">
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 300 }}
                                      overlay={<Tooltip id="button-tooltip">{task.name}</Tooltip>}
                                    >
                                      <div className="text-truncate" style={{ width: '200px' }}>
                                        <Link to={`/tasks/${task.uniqueTaskId}`} className="fs-5 text-gray-900 mb-0">{task.name}</Link>
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="py-1 d-flex flex-column">
                              <div className="d-flex align-items-center position-relative mb-2">
                                <div className="fw-semibold ms-2">
                                  <span className="fs-5 text-gray-900 mb-0">There are no tasks to show!</span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">Owner</span>
                      <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title={project.owner.fullName}>
                        <span className="symbol-label solid bg-danger text-inverse-danger fw-normal">
                          {project.owner.initials}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="d-flex flex-column justify-content-end pe-0">
                        <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">Members</span>
                        <div className="symbol-group symbol-hover flex-nowrap">
                          {project.ProjectUsers.length > 3 ? (
                            <>
                              {project.ProjectUsers.slice(0, 2).map((projectUser, index) => {
                                const backgroundColors = ['bg-warning', 'bg-primary'];
                                const textColors = ['text-inverse-warning', 'text-inverse-primary'];
                                const bgColor = backgroundColors[index % 2];
                                const textColor = textColors[index % 2];
                                return (
                                  <div
                                    key={projectUser.id}
                                    className="symbol symbol-35px symbol-circle"
                                    data-bs-toggle="tooltip"
                                    title={projectUser.fullName}
                                  >
                                    <span className={`symbol-label ${index % 2 === 0 ? 'solid' : 'outline'} fw-normal ${bgColor} ${textColor}`}>
                                      {projectUser.initials}
                                    </span>
                                  </div>
                                );
                              })}
                              <span
                                className="symbol symbol-35px symbol-circle"
                              >
                                <span className="symbol-label solid fs-8 fw-bold">+{project.ProjectUsers.length - 2}</span>
                              </span>
                            </>
                          ) : (
                            <>
                              {project.ProjectUsers.map((projectUser, index) => {
                                const backgroundColors = ['bg-warning', 'bg-danger'];
                                const textColors = ['text-inverse-warning', 'text-inverse-danger'];
                                const bgColor = backgroundColors[index % 2];
                                const textColor = textColors[index % 2];
                                return (
                                  <div
                                    key={projectUser.id}
                                    className="symbol symbol-35px symbol-circle"
                                    data-bs-toggle="tooltip"
                                    title={projectUser.fullName}
                                  >
                                    <span className={`symbol-label ${index % 2 === 0 ? 'solid' : 'outline'} fw-normal ${bgColor} ${textColor}`}>
                                      {projectUser.initials}
                                    </span>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </>
  );
}
