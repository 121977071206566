import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuth } from '../../Redux/slices/auth';
import { useLoginMutation } from '../../Redux/services/auth';
import { encryptPassword, encryptRole } from '../../utils/cryptoUtils';

const publicURL = process.env.REACT_APP_PUBLIC_URL;
function Login() {
  const [loginUser, { isLoading: isLoggingIn }] = useLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { email, password } = data;
    const encryptedPwd = encryptPassword(password);
    const user = await loginUser({
      emailId: email,
      password: encryptedPwd,
    });
    if (user?.data?.success) {
      const {
        fullName, emailId, userId, role, token,
      } = user.data.data;
      const encryptedRole = encryptRole(role ?? {});
      localStorage.setItem('token', token);
      localStorage.setItem('role', JSON.stringify(encryptedRole));
      dispatch(setAuth({
        fullName, emailId, userId, role, token, isAuthenticated: true,
      }));
      window.toastr.success('Login successful!');
      navigate('/');
    }
    if (user?.error) {
      if (user?.error?.status === 400) {
        window.toastr.error("Email or Password didn't match");
      } else {
        window.toastr.error(user?.error?.message);
      }
    }

    return null;
  };
  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-xl-row flex-column-fluid">
        <div className="d-flex flex-column flex-lg-row-fluid">
          <div className="d-flex flex-row-fluid flex-center p-10 pt-20 pb-0">
            <div className="d-flex flex-column">
              <Link to="/" className="mb-7">
                <img alt="Logo" src={`${publicURL}/assets/media/logos/workeazy-light-logo.png`} className="h-70px theme-light-show" />
                <img
                  alt="Logo"
                  src={`${publicURL}/assets/media/logos/workeazy-dark-logo.png`}
                  className="h-70px theme-dark-show"
                />
              </Link>
              <p className="fw-normal mb-2 text-muted" style={{ fontSize: '30px' }}>Your tool to</p>
              <h1 className=" mb-2" style={{ fontSize: '35px', color: '#02638E' }}>Manage your work in one place</h1>
            </div>
          </div>
          <div
            className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-xl-300px mb-xl-0 theme-light-show"
            style={{ backgroundImage: `url(${publicURL}/assets/media/login-img/login.png)`, marginLeft: '-8rem' }}
          />
          <div
            className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-xl-300px mb-xl-0 theme-dark-show"
            style={{ backgroundImage: `url(${publicURL}/assets/media/login-img/login-dark.png)`, marginLeft: '-8rem' }}
          />
          <div className="d-flex mt-2 flex-center mb-20">
            <img
              src={`${publicURL}/assets/media/login-img/mind-it-systems.png`}
              alt=""
              className="w-125px theme-light-show"
              width="100%"
              style={{ marginLeft: '-10rem' }}
            />
            <img
              src={`${publicURL}/assets/media/login-img/mind-it-systems-white.png`}
              alt=""
              className="w-125px theme-dark-show"
              width="100%"
              style={{ marginLeft: '-10rem' }}
            />
          </div>
        </div>
        <div className="flex-row-fluid d-flex flex-center justfiy-content-xl-first p-10">
          <div className=" card d-flex flex-center p-15 shadow-sm rounded-3 w-100 w-md-400px ms-xl-10">
            <form className="form w-100" id="kt_free_trial_form" onSubmit={handleSubmit(onSubmit)}>
              <div className="text-center mb-10 mt-10">
                <div className="d-flex flex-row-fluid flex-center mb-5">
                  <img src={`${publicURL}/assets/media/logos/logo.png`} alt="" className="w-70px theme-light-show" />
                  <img src={`${publicURL}/assets/media/logos/logo-white.png`} alt="" className="w-70px theme-dark-show" />
                </div>
                <h1 className="text-dark mb-10 mt-10">Sign in to WorkEazy</h1>
              </div>
              <div className="fv-row mb-10">
                <label htmlFor="email" className="form-label fs-6 fw-bold text-dark required">Email</label>
                <input {...register('email', { required: { value: true, message: 'Email is required' }, pattern: { value: /^\S+@\S+\.\S+$/, message: 'Please insert a valid email address' } })} id="email" name="email" type="text" className="form-control" />
                {errors?.email && <div className="text-danger small"> {errors.email?.message} </div>}
              </div>
              <div className="fv-row mb-10 position-relative">
                <div className="d-flex flex-stack mb-2">
                  <label htmlFor="password" aria-label="password" className="form-label fw-bold text-dark fs-6 mb-0 required">Password</label>
                  <Link to="/forgot-password" className="fs-6 fw-bold">Forgot Password ?</Link>
                </div>
                <input
                  {...register('password', { required: { value: true, message: 'Password is required' } })}
                  id="password"
                  className="form-control"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  autoComplete="off"
                />
                <span
                  onClick={() => setShowPassword((prev) => !prev)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setShowPassword((prev) => !prev);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  aria-label={showPassword ? 'Hide password' : 'Show password'}
                  className={`fa fa-fw ${!showPassword ? 'fa-eye' : 'fa-eye-slash'} field-icon toggle-password" `}
                  style={{ top: '3rem', right: '10px', position: 'absolute' }}
                />
                {errors?.password
                  && (
                  <div className="small text-danger position-absolute">
                    {errors.password?.message}
                  </div>
                  )}
              </div>
              <div className="text-center">
                <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
                  {!isLoggingIn ? <span className="indicator-label">Login</span>
                    : (
                      <span>Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
