import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import CustomPagination from '../custom/Pagination';
import { useGetTasksListWithFiltersQuery } from '../../Redux/services/task';
import { taskStatusConstants } from '../../utils/constant';

const taskStatusMap = {
  toDo: {
    id: taskStatusConstants['TO DO'],
    status: 'To Do',
    statusIcon: <i className="fa fa-list-check fs-1 me-4" />,
    countColor: 'text-info',
  },
  inProgress: {
    id: taskStatusConstants['IN PROGRESS'],
    status: 'In Progress',
    statusIcon: <i className="fa fa-spinner fs-1 me-4" />,
    countColor: 'text-warning',
  },
  completed: {
    id: taskStatusConstants.COMPLETED,
    status: 'Completed',
    statusIcon: <i className="fa-regular fa-circle-check fs-1 me-4" />,
    countColor: 'text-success',
  },
};

const statusColorMap = {
  'To do': 'badge-light-info',
  'In Progress': 'badge-light-warning',
  Complete: 'badge-light-success',
};

function WeeklyTasks() {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tasksCategory, setTasksCategory] = useState(() => taskStatusMap);
  const size = 5;
  const [taskType, setTaskType] = useState([]);
  const { data: weeklyTasks } = useGetTasksListWithFiltersQuery({
    filters: { status: taskType }, sortColumn, sortOrder, page: currentPage, size, weekly: true,
  });
  const { data: weeklyTasksForCounts } = useGetTasksListWithFiltersQuery({
    weekly: true,
  });

  useEffect(() => {
    if (weeklyTasks?.data?.taskCount) {
      const totalTasks = parseInt(weeklyTasks?.data?.taskCount?.toDo, 10)
        + parseInt(weeklyTasks.data.taskCount?.inProgress, 10)
        + parseInt(weeklyTasks.data.taskCount?.completed, 10);
      setTotalCount(totalTasks);
    }
    if (weeklyTasksForCounts?.data?.taskCount) {
      setTasksCategory((tasks) => Object.values(tasks)?.map((task, index) => ({
        ...task,
        taskCount: Object.values(weeklyTasksForCounts.data.taskCount)[index],
      })));
    }
  }, [weeklyTasksForCounts?.data?.taskCount, weeklyTasks?.data?.taskCount]);

  const handleColumnSort = (columnName) => {
    let orderBy = 'asc';
    if (sortColumn === columnName) {
      orderBy = sortOrder === 'asc' ? 'desc' : 'asc';
    }
    setSortOrder(orderBy);
    setSortColumn(columnName);
    setCurrentPage(1);
  };

  const handleStatusFilter = (id) => {
    const ids = taskType;
    if (ids.includes(id)) {
      setTaskType(ids.filter((taskId) => taskId !== id));
    } else {
      setTaskType((taskId) => [...taskId, id]);
    }
    setCurrentPage(1);
  };

  return (

    <div className="row mt-5 pt-0">
      <div className="col-xl-12 col-lg-12 col-md-12">
        <div className="d-flex justify-content-between align-items-start flex-wrap mb-0">
          <div className="fs-2 fw-bold">
            <h2 className="mb-3">
              Weekly
            </h2>
          </div>
        </div>
        <div className="row">
          {Object.values(tasksCategory)?.map((item) => (
            <div
              role="button"
              key={item.status}
              className="col-xxl-2 col-md-4 col-sm-6 cursor-pointer"
              onClick={() => handleStatusFilter(item.id)}
              aria-label="Task Count"
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleStatusFilter(item.id);
                }
              }}
              tabIndex="0"
            >
              <div className={`card h-100 ${taskType.includes(item.id) && 'border-danger'} user-task-card`}>
                <div className="card-body py-3 px-5">
                  <input
                    style={{
                      width: '15px', height: '15px', position: 'absolute', right: '5%', borderRadius: '0.2em',

                    }}
                    className="form-check-input user-task"
                    type="checkbox"
                    checked={taskType.includes(item.id)}
                  />
                  <div className=" mt-3 gap-3">
                    <div className="d-flex">
                      <div>
                        {item.statusIcon}
                      </div>
                      <div>
                        <h5 className="card-title mb-1 text-gray-600 fs-4">{item.status}</h5>
                        <h4 className={`mb-0 ${item.countColor} fs-1`}>{item.taskCount && (item.taskCount.toString().length === 1 && '0')}{item.taskCount}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row mt-5 pt-0">
          <div className="col-xl-12 col-lg-12 col-md-12">

            <div className="row gy-5 g-xl-10">

              <div className="col-xl-12">
                <div className="card priority-card mt-0">

                  <div className="card-header py-4">
                    <h5 className="card-title mb-0">Tasks</h5>

                  </div>
                  <div className="card-body p-4 py-2 table-responsive">

                    <table
                      className="table data-table align-middle table-row-dashed fs-6 gy-5"
                      id="kt_ecommerce_sales_table"
                    >
                      <thead>
                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                          <th className="sortable" style={{ width: '100px' }} onClick={() => handleColumnSort('taskId')}>
                            <span role="button">Task ID</span>
                            {sortColumn === 'taskId' && (
                              <span className="sorting-icons m-2">
                                <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                                <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                              </span>
                            )}
                          </th>
                          <th className="sortable" style={{ width: '300px' }} onClick={() => handleColumnSort('title')}>
                            <span role="button">Title</span>
                            {sortColumn === 'title' && (
                              <span className="sorting-icons m-2">
                                <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                                <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                              </span>
                            )}
                          </th>
                          <th className="sortable" style={{ width: '150px' }} onClick={() => handleColumnSort('project')}>
                            <span role="button">Project</span>
                            {sortColumn === 'project' && (
                              <span className="sorting-icons m-2">
                                <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                                <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                              </span>
                            )}
                          </th>
                          <th className="sortable" style={{ width: '100px' }} onClick={() => handleColumnSort('startDate')}>
                            <span role="button">Start Date</span>
                            {sortColumn === 'startDate' && (
                              <span className="sorting-icons m-2">
                                <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                                <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                              </span>
                            )}
                          </th>
                          <th className="sortable" style={{ width: '100px' }} onClick={() => handleColumnSort('endDate')}>
                            <span role="button">End Date</span>
                            {sortColumn === 'endDate' && (
                              <span className="sorting-icons m-2">
                                <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                                <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                              </span>
                            )}
                          </th>
                          <th className="sortable" style={{ width: '120px' }}>Users</th>
                          <th className="sortable" style={{ width: '120px' }} onClick={() => handleColumnSort('status')}>
                            <span role="button">Status</span>
                            {sortColumn === 'status' && (
                              <span className="sorting-icons m-2">
                                <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                                <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                              </span>
                            )}
                          </th>
                          <th className="sortable" style={{ width: '100px' }}>Actions</th>

                        </tr>
                      </thead>

                      <tbody className="fw-semibold text-gray-600">
                        {weeklyTasks?.data?.tasks.map((task) => (
                          <tr key={task?.id} onClick={() => navigate(`/tasks/${task?.uniqueTaskId}`)}>
                            <td style={{ width: '100px' }}><span className="text-gray-800 fs-5 fw-bold">{task?.uniqueTaskId}</span></td>
                            <td style={{ width: '300px' }}>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 300 }}
                                overlay={<Tooltip id="button-tooltip">{task.title}</Tooltip>}
                              >
                                <div style={{ maxWidth: '250px' }} className="text-truncate">
                                  <span className="text-gray-800 fs-5 fw-bold">{task?.title}</span>
                                </div>
                              </OverlayTrigger>
                            </td>
                            <td style={{ width: '150px' }}>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 300 }}
                                overlay={<Tooltip id="button-tooltip">{task?.Project?.name}</Tooltip>}
                              >
                                <div style={{ maxWidth: '130px' }} className="text-truncate">
                                  <span className="text-gray-800 fs-5 fw-bold">{task?.Project?.name}</span>
                                </div>
                              </OverlayTrigger>
                            </td>
                            <td style={{ width: '100px' }}>{task?.startDate ? moment(task?.startDate, 'YYYY-MM-DD').format('DD MMM YY') : '---'}</td>
                            <td style={{ width: '100px' }}>{task?.endDate ? moment(task?.endDate, 'YYYY-MM-DD').format('DD MMM YY') : '---'}</td>
                            <td style={{ width: '120px' }}>
                              <div className="symbol-group symbol-hover mb-1">
                                {task?.taskUsers?.length > 3 ? (
                                  <>
                                    {task?.taskUsers?.slice(0, 2).map((user, index) => {
                                      const backgroundColors = ['bg-warning', 'bg-primary'];
                                      const textColors = ['text-inverse-warning', 'text-inverse-primary'];
                                      const bgColor = backgroundColors[index % 2];
                                      const textColor = textColors[index % 2];
                                      return (
                                        <div
                                          key={user.id}
                                          className="symbol symbol-25px symbol-circle"
                                          data-bs-toggle="tooltip"
                                          title={user.fullName}
                                        >
                                          <span className={`symbol-label ${index % 2 === 0 ? 'solid' : 'outline'} fs-7 fw-normal ${bgColor} ${textColor}`}>
                                            {user.initials}
                                          </span>
                                        </div>
                                      );
                                    })}
                                    <span
                                      className="symbol symbol-25px symbol-circle"
                                      data-bs-toggle="tooltip"
                                      title={`+${task.taskUsers.length - 2} more`}
                                    >
                                      <span className="symbol-label solid fs-7 fw-normal">+{task.taskUsers.length - 2}</span>
                                    </span>
                                  </>
                                ) : task?.taskUsers?.map((user, index) => {
                                  const backgroundColors = ['bg-warning', 'bg-danger'];
                                  const textColors = ['text-inverse-warning', 'text-inverse-danger'];
                                  const bgColor = backgroundColors[index % 2];
                                  const textColor = textColors[index % 2];
                                  return (
                                    <div
                                      key={user.id}
                                      className="symbol symbol-25px symbol-circle"
                                      data-bs-toggle="tooltip"
                                      title={user.fullName}
                                    >
                                      <span className={`symbol-label ${index % 2 === 0 ? 'solid' : 'outline'} fs-7 fw-normal ${bgColor} ${textColor}`}>
                                        {user.initials}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                              {weeklyTasks?.taskUsers?.length === 0 && <div>  --- </div>}
                            </td>
                            <td style={{ width: '120px' }}>
                              <div className={`badge ${statusColorMap[task?.TaskStatus?.name]} fw-bold`}>{task?.TaskStatus?.name}
                              </div>
                            </td>
                            <td style={{ width: '100px' }}>
                              <Link to={`/tasks/${task?.uniqueTaskId}`}><i className="fa fa-eye me-3" aria-label="View Task Details" /></Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {weeklyTasks?.data?.tasks?.length > 0 ? (
                      <CustomPagination
                        dataPerPage={size}
                        totalData={totalCount}
                        currentPage={currentPage}
                        paginate={setCurrentPage}
                      />
                    )
                      : <div className="text-center fw-bold mx-auto m-4">No Records Found!</div>}
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
  );
}

export default WeeklyTasks;
