import { useEffect } from 'react';
import { useGetTemplatesQuery } from '../../../../Redux/services/report';

function TemplatesList({
  selectedTemplateId, setSelectedTemplateId, setSelectedTemplateIdForUpdation,
  setIsMobileCompatible,
}) {
  const { data: templatesData, isLoading } = useGetTemplatesQuery();

  useEffect(() => {
    setSelectedTemplateId(templatesData?.data?.[0]?.id);
    setSelectedTemplateIdForUpdation(templatesData?.data?.[0]?.id);
    setIsMobileCompatible(templatesData?.data?.[0]?.mobile ?? false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatesData?.data]);

  return (
    <div className="scroll-y h-200px mx-10 mb-10">
      <div className="card-body pe-10">
        <div className="menu-item">
          <div className="menu-content fs-8 text-dark fw-bold px-3 py-4 text-uppercase">
            Saved Templates
          </div>
        </div>

        {isLoading && (
        <div className="spinner-border text-primary d-flex m-auto mt-18" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        )}
        {(templatesData?.data?.map((template) => (
          <div key={template.id} className="d-flex justify-content-between p-1 flex-grow-1">
            <div className="d-flex fs-6 fw-normal align-items-center">
              <i className="bullet-dot rounded-2 bg-primary me-3 mb-3" />
              <span
                className="text-primary"
                style={{
                  cursor: 'pointer',
                  fontWeight: template.id === selectedTemplateId ? 'bold' : 'normal',
                }}
                onClick={() => {
                  setSelectedTemplateId(template.id);
                  setSelectedTemplateIdForUpdation(template.id);
                  setIsMobileCompatible(template.mobile ?? false);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSelectedTemplateId(template.id);
                    setSelectedTemplateIdForUpdation(template.id);
                    setIsMobileCompatible(template.mobile ?? false);
                  }
                }}
                aria-label="button"
                tabIndex={0}
                role="button"
              >
                {template.name}
              </span>
            </div>
          </div>
        ))
        )}
      </div>
    </div>
  );
}

export default TemplatesList;
