import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDeleteNotificationsMutation, useGetNotificationsQuery } from '../../Redux/services/notifications';

export default function Notifications() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [id, setId] = useState('');
  const [deleteNotifications, { isLoading }] = useDeleteNotificationsMutation();

  const { data: notifications } = useGetNotificationsQuery({ all: false });

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const handleDeleteAll = async () => {
    try {
      const allNotificationIds = notifications?.data?.map((notification) => notification?.id);
      const response = await deleteNotifications({ id: allNotificationIds });
      if (response?.data?.success) {
        window.toastr.success(response.data.message);
      } else {
        window.toastr.error('Something went wrong!');
      }
    } catch (err) {
      window.toastr.error('Something went wrong!');
    }
  };

  const handleRemove = async (notificationId) => {
    try {
      const response = await deleteNotifications({ id: [notificationId] });
      if (response?.data?.success) {
        window.toastr.success(response.data.message);
      } else {
        window.toastr.error('Something went wrong!');
      }
    } catch (err) {
      window.toastr.error('Something went wrong!');
    }
  };

  return (
    <div className="d-flex align-items-center ms-2 ms-lg-3">
      <div
        className={`btn btn-icon btn-active-primary position-relative w-35px h-35px w-md-40px h-md-40px ${isDropdownOpen ? 'show' : ''}`}
        data-kt-menu-trigger="{default:'click', lg: 'hover'}"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        aria-label="Toggle notifications dropdown"
      >
        <span className="svg-icon svg-icon-1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="currentColor" />
            <path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="currentColor" />
          </svg>
          {notifications?.data?.length > 0 && (
            <span
              className="badge badge-danger notification position-absolute justify-content-center"
              style={{
                transform: 'translate(-10px, 0)',
                height: '20px',
                width: notifications?.data?.length > 99 ? 'fit-content' : '20px',
                borderRadius: '10px',
              }}
            >
              {notifications?.data?.length > 99 ? '99+' : notifications?.data?.length }
            </span>
          )}
        </span>
      </div>
      <div className={`menu menu-sub menu-sub-dropdown menu-column w-350px ${isDropdownOpen ? 'show' : ''}`} data-kt-menu="true">
        <div className="card">
          <div className="card-header align-items-center">
            <h3 className="card-title">Notifications</h3>
            <button
              className="btn btn-light-danger btn-sm w-fit h-fit d-flex justify-content-center"
              type="button"
              disabled={notifications?.data?.length === 0}
              onClick={handleDeleteAll}
            >
              {!isLoading ? 'Delete All' : (
                <span>
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              )}
            </button>
          </div>
          <div className="card-body p-0">
            <div className="mh-350px py-3" style={{ overflowY: 'auto' }}>
              {/* eslint-disable-next-line */}
              {notifications?.data?.length > 0 && notifications?.data?.map((notification, index) => (
                <div
                  key={notification.id}
                  className="hoverNotification"
                >
                  <div className="d-flex align-items-center py-3 px-9">
                    <div className="symbol symbol-40px symbol-circle me-5">
                      <span className="symbol-label bg-light-primary">
                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor" />
                            <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor" />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div className="mb-1 pe-3 flex-grow-1">
                      <Link to={notification?.uniqueTaskId ? `/tasks/${notification?.uniqueTaskId}` : `/projectDetails/${notification?.ProjectId}`}>
                        {/* eslint-disable-next-line */}
                        <div className="fs-6 fw-semibold text-hover-primary text-dark" dangerouslySetInnerHTML={{ __html: notification.message }} title={`Go to ${notification?.type ?? 'details'}`}/>
                      </Link>
                      <div className="text-dark fw-semibold fs-7" title={moment(notification.timestamp, 'YYYY-MM-DD hh:mm A').format('DD-MM-YYYY HH:mm A')}>
                        {moment(notification.timestamp, 'YYYY-MM-DD hh:mm A').fromNow()}
                      </div>
                    </div>
                    {id !== notification.id
                      && (
                        <button
                          className="border-0 bg-transparent"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setId(notification.id);
                            handleRemove(notification.id);
                          }}
                          disabled={isLoading}
                          aria-label="button"
                          title="Delete notification"
                        >
                          <i className="fa-solid fa-xmark fs-4 text-hover-danger cursor-pointer" />
                        </button>
                      )}
                    {isLoading && (id === notification.id) && (
                    <span>
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                    )}
                  </div>
                  {index !== notifications.data.length - 1 && <div className="separator separator-dashed my-1 mx-5" />}
                </div>
              ))}
              {notifications?.data?.length === 0 && (<p className="text-center">No notifications to show</p>) }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
