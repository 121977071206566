import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  fullName: '',
  token: '',
  userId: '',
  emailId: '',
  role: {},
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      const {
        token = '', fullName = '', emailId = '', userId = '', role = {}, isAuthenticated = false,
      } = action.payload;
      Object.assign(state, {
        fullName,
        token,
        emailId,
        userId,
        role,
        isAuthenticated,
      });
    },
    clearAuth: (state) => {
      Object.assign(state, {
        isAuthenticated: false,
        fullName: '',
        token: '',
        userId: '',
        emailId: '',
        role: {},
      });
    },
  },
});

export const { setAuth, clearAuth } = authSlice.actions;
export default authSlice.reducer;
