import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_CRYPTO_KEY;
const ivKey = process.env.REACT_APP_CRYPTO_IV;
const roleKey = process.env.REACT_APP_ROLE_KEY;

const key = CryptoJS.enc.Hex.parse(secretKey);
const iv = CryptoJS.enc.Hex.parse(ivKey);

export function encryptPassword(plainPassword = '') {
  const encrypted = CryptoJS.AES.encrypt(plainPassword, key, {
    iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
}

export function decryptPassword(encryptedPassword = '') {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedPassword);
  const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const decrypted = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
    iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function encryptRole(role = {}) {
  const encryptedRole = CryptoJS.AES.encrypt(JSON.stringify(role), roleKey);
  return encryptedRole.toString();
}

export function decryptRole(role = '') {
  const decryptedRole = CryptoJS.AES.decrypt(JSON.parse(role), roleKey).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedRole);
}
