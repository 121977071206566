import ReportFilterFields from './ReportFilterFields';
import ReportTable from './ReportTable';

function ReportTableAndFilterFieldsContainer({
  customFields, customCheckedFields, setFilter, filtersData,
  setCurrentPage, currentPage,
}) {

  return (
    <>
      <ReportFilterFields
        setFilter={setFilter}
        customFields={customFields}
        filtersData={filtersData}
        customCheckedFields={customCheckedFields}
        setCurrentPage={setCurrentPage}
      />
      <ReportTable
        customCheckedFields={customCheckedFields}
        filtersData={filtersData}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
}

export default ReportTableAndFilterFieldsContainer;
