import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST ?? '';
axios.defaults.withCredentials = false;

export default axios;

const axiosInterceptor = () => {
  // Request interceptor
  axios.interceptors.request.use(async (request) => {
    // request.headers['Content-Type'] = 'application/json';
    request.mode = 'cors';

    const csrfToken = 'YOUR_CSRF_TOKEN'; // Replace with the actual token value

    // Include CSRF token in the request headers
    request.headers['X-CSRF-Token'] = csrfToken;

    return request;
  });

  // Response interceptor
  axios.interceptors.response.use(
    async (response) => response.data,
    (error) => {
      if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
        const customTimeoutError = new Error('Request Timed Out. Please try again later.');
        return Promise.reject(customTimeoutError);
      }

      return Promise.reject(error);
    },
  );
};
axiosInterceptor();
